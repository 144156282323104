import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { getDateFormat } from "../../Utility/paramsConvert";
const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    textAlign: "left",
    margin: "20px 0",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardBody: {
    fontWeight: 700,
    fontSize: "16px",
  },
}));

export default function NotificationCard({ notification }) {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography className={classes.cardBody} variant="h6">
            {notification.potif_text}
          </Typography>
        </Box>
        <Box style={{ marginTop: "10px" }}>
          <Typography
            style={{ display: "flex", alignItems: "center", fontSize: "12px" }}
          >
            <AccessTimeIcon style={{ fontSize: "15px", marginRight: "4px" }} />
            {getDateFormat(notification.modified)}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}
