import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import MessageIcon from "@material-ui/icons/Message";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { blockStyle, BootstrapButton } from "../../../Utility/Style";
import Tooltip from "@material-ui/core/Tooltip";
import {
  getDateFormat,
  removeUnderscoreConvertInSmallcase,
  convertPaisaToRupee,
  convertTimestamptoTime,
  epochDateConversionToUtc,
  convert_is_ondc_order,
} from "../../../Utility/paramsConvert";
import {
  orderDetailsLabels,
  orderTrailedLabels,
  orderOtherChangesLabels,
  contactDetailLables,
  summaryLabels,
  orderDataModalHeadersSubheaders,
  numberForConversionInKilometers,
  constTrue,
  constFalse,
  constNotAvailable,
  constDocument,
  smartBoxKeys,
  daConstants,
  constOrderTypePnD,
  ratingKeys,
  platformCharges,
  paymentFields,
  externalContactKeys,
  thirdPartyDeliveryLabels,
  thirdPartyDeliveryKeys,
  labelUrl,
  manifestUrl,
  invoiceUrl,
  orderInvoiceUrl,
} from "../../../Utility/stringConstant";
import SendNotificationModal from "./SendNotificationModal";
import EditOrderDetailsModal from "./EditOrderDetailsModal";

function OrderModal({
  orderDataDisplayModalOpen,
  modalOrderData,
  onHandleCloseModalWithOrderDetail,
  onHandleChangeCopyTextToCopy,
  onHandleChangeCopyTextToCopied,
  copyText,
  onHandleGetOrderDataByShortID,
}) {
  const useStyles = makeStyles(blockStyle);
  const classes = useStyles();
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState("");
  const [editOrderData, setModalEditOrderData] = useState("");
  const [sendNotificationDataModalOpen, setNotificationDataDisplayModalOpen] =
    useState(false);
  const [editOrderDataDisplayModalOpen, setEditOrderDataDisplayModalOpen] =
    useState(false);

  const handleCloseModalWithSendNotification = () => {
    setNotificationDataDisplayModalOpen(false);
  };
  const handleCloseModalWithEditOrderDetail = () => {
    setEditOrderDataDisplayModalOpen(false);
  };
  const handleNotificationDisplayOpenModal = (customerPhoneNumber) => {
    setCustomerPhoneNumber(customerPhoneNumber);

    setNotificationDataDisplayModalOpen(true);
  };
  const handleEditOrderDisplayOpenModal = () => {
    setModalEditOrderData(modalOrderData);
    setEditOrderDataDisplayModalOpen(true);
  };

  const getDADetails = (da_info, da_constant) => {
    if (da_info.user_profile != null && da_constant === daConstants.da_id) {
      return da_info.user_profile.user_id;
    } else if (
      da_info.profile_name != null &&
      da_constant === daConstants.profile_name
    ) {
      return da_info.profile_name;
    }
    return constNotAvailable;
  };

  const getAddress = (data) => {
    let address;
    if (data) {
      if (data.geo_addr) {
        address =
          data.geo_addr.house +
          " " +
          data.geo_addr.landmark +
          " " +
          data.geo_addr.pincode +
          " " +
          data.pretty_address;
      } else {
        address = "" + data.pretty_address;
      }
    } else {
      address = constNotAvailable;
    }
    return address;
  };

  const getPaymentFields = (data, key) => {
    if (data != null) {
      let paymentData = {
        amount: data.amount,
        refund_amount: data.refund_amount,
      };
      let returnValue = paymentData[key];
      if (returnValue != null) {
        return returnValue;
      }
    }
    return constNotAvailable;
  };

  const getExternalInfo = (data, key) => {
    if (data != null) {
      let contactInfo = {
        name: data.name,
        phone: data.phone,
      };
      let returnValue = contactInfo[key];
      if (returnValue != null) {
        return returnValue;
      }
    }
    return constNotAvailable;
  };

  const getRating = (data, key) => {
    if (data != null) {
      let orderRatingData = {
        da_rating: data.delivery_ratings.rating_val,
        order_rating: data.rating_value,
      };
      let returnValue = orderRatingData[key];
      if (returnValue != null) {
        return returnValue;
      }
    }
    return constNotAvailable;
  };
  const getThirdPartDelivery = (data, key) => {
    if (data != null) {
      let thirdPartyDeliveryData = {
        delivery_type: data.delivery_type,
        order_id: data.order_id,
        shipment_id: data.shipment_id,
        awb_code: data.awb_code,
        label_url: data.label_url,
        manifest_url: data.manifest_url,
        invoice_url: data.invoice_url,
      };
      let returnValue = thirdPartyDeliveryData[key];
      if (returnValue != null) {
        return returnValue;
      }
    }
    return constNotAvailable;
  };
  const getSmartboxDelivery = (smartbox_info, key) => {
    if (smartbox_info != null) {
      let smartboxManualDelivery;
      if (smartbox_info.smartbox_manual_delivery) {
        smartboxManualDelivery = constTrue;
      } else {
        smartboxManualDelivery = constFalse;
      }
      let smartboxDelivery = {
        smartbox_delivery: smartboxManualDelivery,
        smartbox_id: smartbox_info.smartbox_id,
        smartbox_compartment_id: smartbox_info.compartment_id,
        pretty_address: smartbox_info.smartbox_address.pretty_address,
      };
      let returnValue = smartboxDelivery[key];
      if (returnValue != null) {
        return returnValue;
      }
    }
    return constNotAvailable;
  };

  const getDocumentLink = (print_document_info) => {
    if (print_document_info != null && print_document_info.document != null) {
      return print_document_info.document.doc_url;
    }
    return null;
  };
  return (
    <>
      <SendNotificationModal
        key={modalOrderData.id}
        sendNotificationDataModalOpen={sendNotificationDataModalOpen}
        customerPhoneNumber={customerPhoneNumber}
        onHandleNotificationDisplayOpenModal={
          handleNotificationDisplayOpenModal
        }
        onHandleCloseModalWithNotification={
          handleCloseModalWithSendNotification
        }
      />

      <EditOrderDetailsModal
        key={modalOrderData.id}
        editOrderDataDisplayModalOpen={editOrderDataDisplayModalOpen}
        modalOrderData={modalOrderData}
        onHandleCloseModalWithEditOrder={handleCloseModalWithEditOrderDetail}
        onHandleGetOrderDataByShortID={onHandleGetOrderDataByShortID}
      />

      <Modal
        style={{ width: "100%" }}
        key={modalOrderData.order_id}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={orderDataDisplayModalOpen}
        onClose={onHandleCloseModalWithOrderDetail}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={orderDataDisplayModalOpen}>
          <div className={classes.modalOuter}>
            <div className={classes.modalPaper}>
              <Box display="flex" justifyContent="flex-end">
                <IconButton onClick={onHandleCloseModalWithOrderDetail}>
                  <CloseIcon />
                </IconButton>
              </Box>

              <Box display="flex" justifyContent="flex-end">
                <BootstrapButton
                  variant="contained"
                  color="primary"
                  onClick={handleEditOrderDisplayOpenModal}
                >
                  Edit Order Details
                </BootstrapButton>
              </Box>
              <Box>
                <h3 id="transition-modal-title">
                  {orderDataModalHeadersSubheaders.order_details}
                </h3>
                <hr />
                <Box>
                  <Box display="flex">
                    <Grid item xs={6} md={6} style={{ display: "flex" }}>
                      <p>
                        <strong>
                          {orderDataModalHeadersSubheaders.order_ID} :{" "}
                        </strong>
                      </p>
                      <Box style={{ display: "flex", alignItems: "center" }}>
                        <p style={{ marginLeft: "20px" }}>
                          {modalOrderData.order_id}
                        </p>
                        <Box
                          onClick={onHandleChangeCopyTextToCopied}
                          onMouseOver={onHandleChangeCopyTextToCopy}
                        >
                          <Tooltip title={copyText} aria-label="Copy">
                            <CopyToClipboard text={modalOrderData.order_id}>
                              <FileCopyIcon
                                style={{
                                  height: "16px",
                                  cursor: "pointer",
                                  color: "grey",
                                }}
                              />
                            </CopyToClipboard>
                          </Tooltip>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6} md={6} style={{ display: "flex" }}>
                      <p>
                        <strong>
                          {orderDataModalHeadersSubheaders.order_short_ID} :{" "}
                        </strong>
                      </p>
                      <Box style={{ display: "flex", alignItems: "center" }}>
                        <p style={{ marginLeft: "20px" }}>
                          {modalOrderData.order_short_number}
                        </p>
                        <Box
                          onClick={onHandleChangeCopyTextToCopied}
                          onMouseOver={onHandleChangeCopyTextToCopy}
                        >
                          <CopyToClipboard
                            text={modalOrderData.order_short_number}
                          >
                            <Tooltip title={copyText} aria-label="Copy">
                              <FileCopyIcon
                                style={{
                                  height: "16px",
                                  cursor: "pointer",
                                  color: "grey",
                                }}
                              />
                            </Tooltip>
                          </CopyToClipboard>
                        </Box>
                      </Box>
                    </Grid>
                  </Box>
                  <Box display="flex">
                    <Grid item xs={6} md={6} style={{ display: "flex" }}>
                      <p>
                        <strong>
                          {orderDataModalHeadersSubheaders.delivery_slot} :{" "}
                        </strong>
                      </p>
                      {modalOrderData.time_slot ? (
                        <div>
                          <p style={{ marginLeft: "20px" }}>
                            {epochDateConversionToUtc(
                              modalOrderData.time_slot.from
                            )}
                            <span style={{ marginLeft: "15px" }} />
                            {convertTimestamptoTime(
                              modalOrderData.time_slot.from
                            ) +
                              " - " +
                              convertTimestamptoTime(
                                modalOrderData.time_slot.to
                              )}
                          </p>
                        </div>
                      ) : (
                        <p style={{ marginLeft: "20px" }}>Not Available</p>
                      )}
                    </Grid>
                    <Grid item xs={6} md={6} style={{ display: "flex" }}>
                      <p>
                        <strong>
                          {orderDataModalHeadersSubheaders.is_ondc_order} :{" "}
                        </strong>
                      </p>
                      <p style={{ marginLeft: "20px" }}>
                        {convert_is_ondc_order(modalOrderData.is_ondc_order)}
                      </p>
                    </Grid>
                  </Box>
                  <Box display="flex">
                    {modalOrderData.order_type === constOrderTypePnD ? (
                      <Grid item xs={6} md={6} style={{ display: "flex" }}>
                        <p>
                          <strong>
                            {orderDataModalHeadersSubheaders.item_type} :{" "}
                          </strong>
                        </p>
                        <p style={{ marginLeft: "20px" }}>
                          {removeUnderscoreConvertInSmallcase(
                            modalOrderData.item_type
                          )}
                        </p>
                      </Grid>
                    ) : null}
                    <Grid item xs={6} md={6} style={{ display: "flex" }}>
                      <p>
                        <strong>
                          {
                            orderDataModalHeadersSubheaders.delivery_automation_status
                          }{" "}
                          :{" "}
                        </strong>
                      </p>
                      <p style={{ marginLeft: "20px" }}>
                        {removeUnderscoreConvertInSmallcase(
                          modalOrderData.delivery_automation_status
                        )}
                      </p>
                    </Grid>
                    {modalOrderData.is_ondc_order ? (
                      <Grid item xs={6} md={6} style={{ display: "flex" }}>
                        <p>
                          <strong>
                            {orderDataModalHeadersSubheaders.bpp_name} :{" "}
                          </strong>
                        </p>
                        <p style={{ marginLeft: "20px" }}>
                          {modalOrderData.bap_id}
                        </p>
                      </Grid>
                    ) : null}
                  </Box>
                  <Box display="flex">
                    <Grid item xs={6} md={6} style={{ display: "flex" }}>
                      <p>
                        <strong>
                          {orderDataModalHeadersSubheaders.order_invoice_url} :{" "}
                        </strong>
                      </p>
                      <p style={{ marginLeft: "20px" }}>
                        {modalOrderData.order_invoice_url !== null ? (
                          <Link
                            to={{ pathname: modalOrderData.order_invoice_url }}
                            target="_blank"
                          >
                            {orderInvoiceUrl}
                          </Link>
                        ) : (
                          constNotAvailable
                        )}
                      </p>
                    </Grid>
                  </Box>

                  <Box>
                    <TableContainer component={Paper}>
                      <Table
                        className={classes.table}
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            {orderDetailsLabels.map((orderDetailsLabel) => (
                              <TableCell
                                key={orderDetailsLabel.id}
                                align="left"
                              >
                                <b>{orderDetailsLabel.label}</b>
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {modalOrderData
                            ? modalOrderData.order_items.map((orderItem) => (
                                <TableRow key={orderItem.sku_id}>
                                  <TableCell align="left">
                                    {orderItem.sku_id}
                                  </TableCell>
                                  <TableCell align="left">
                                    {orderItem.quantity}
                                  </TableCell>
                                  <TableCell align="left">
                                    {convertPaisaToRupee(orderItem.unit_price)}
                                  </TableCell>
                                  <TableCell align="left">
                                    {orderItem.product_name}
                                  </TableCell>
                                  <TableCell align="left">
                                    {orderItem.variation_option.Weight}
                                  </TableCell>
                                  <TableCell align="left">
                                    {orderItem.composition}
                                  </TableCell>
                                  {getDocumentLink(orderItem.print_item_info) !=
                                  null ? (
                                    <TableCell align="left">
                                      <Link
                                        to={{
                                          pathname: getDocumentLink(
                                            orderItem.print_item_info
                                          ),
                                        }}
                                        target="_blank"
                                      >
                                        {constDocument}
                                      </Link>
                                    </TableCell>
                                  ) : null}
                                </TableRow>
                              ))
                            : null}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                  <Box style={{ marginTop: "20px" }} display="flex">
                    <p>
                      <strong>
                        {orderDataModalHeadersSubheaders.order_trail} :{" "}
                      </strong>
                    </p>
                    <p style={{ marginLeft: "20px" }}>
                      {modalOrderData
                        ? modalOrderData.order_trail.length +
                          modalOrderData.delivery_trail.length
                        : null}{" "}
                      times
                    </p>
                  </Box>
                  <Box>
                    <TableContainer component={Paper}>
                      <Table
                        className={classes.table}
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            {orderTrailedLabels.map((orderTrailedLabel) => (
                              <TableCell
                                key={orderTrailedLabel.id}
                                align="left"
                              >
                                <b>{orderTrailedLabel.label}</b>
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {modalOrderData
                            ? modalOrderData.delivery_trail.map((orderItem) => (
                                <TableRow key={orderItem.sku_id}>
                                  <TableCell align="left">
                                    {removeUnderscoreConvertInSmallcase(
                                      orderItem.event_name
                                    )}
                                  </TableCell>
                                  <TableCell align="left">
                                    {getDateFormat(orderItem.created)}
                                  </TableCell>

                                  <TableCell align="left">
                                    <Box display="flex">
                                      <Typography variant="body2">
                                        <b>
                                          {
                                            orderDataModalHeadersSubheaders.DA_name
                                          }{" "}
                                          :
                                        </b>
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        style={{ marginLeft: "10px" }}
                                      >
                                        {getDADetails(
                                          modalOrderData.da_info,
                                          daConstants.profile_name
                                        )}
                                      </Typography>
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              ))
                            : null}
                          {modalOrderData
                            ? modalOrderData.order_trail.map((orderItem) => (
                                <TableRow key={orderItem.sku_id}>
                                  <TableCell align="left">
                                    {removeUnderscoreConvertInSmallcase(
                                      orderItem.event_name
                                    )}
                                  </TableCell>
                                  <TableCell align="left">
                                    {getDateFormat(orderItem.created)}
                                  </TableCell>

                                  <TableCell align="left">
                                    <Box display="flex">
                                      <Typography variant="body2">
                                        <b>
                                          {
                                            orderDataModalHeadersSubheaders.DA_name
                                          }{" "}
                                          :
                                        </b>
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        style={{ marginLeft: "10px" }}
                                      >
                                        {getDADetails(
                                          modalOrderData.da_info,
                                          daConstants.profile_name
                                        )}
                                      </Typography>
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              ))
                            : null}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                  <Box style={{ marginTop: "20px" }} display="flex">
                    <p>
                      <strong>
                        {orderDataModalHeadersSubheaders.total_other_charges} :{" "}
                      </strong>
                    </p>
                    <p style={{ marginLeft: "20px" }}>
                      {modalOrderData
                        ? modalOrderData.other_charges_detail.length
                        : null}
                    </p>
                  </Box>
                  <Box>
                    <TableContainer component={Paper}>
                      <Table
                        className={classes.table}
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            {orderOtherChangesLabels.map(
                              (orderOtherChangesLabel) => (
                                <TableCell
                                  align="left"
                                  key={orderOtherChangesLabel.id}
                                >
                                  <b>{orderOtherChangesLabel.label}</b>
                                </TableCell>
                              )
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {modalOrderData
                            ? modalOrderData.other_charges_detail.map(
                                (otherCharge, index) => (
                                  <TableRow key={index}>
                                    <TableCell
                                      component="th"
                                      align="left"
                                      scope="row"
                                    >
                                      {otherCharge.name}
                                    </TableCell>
                                    <TableCell align="left">
                                      {convertPaisaToRupee(otherCharge.value)}
                                    </TableCell>
                                  </TableRow>
                                )
                              )
                            : null}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Box>
              </Box>
              <Box>
                <h3 style={{ marginTop: "30px" }} id="transition-modal-title">
                  {orderDataModalHeadersSubheaders.contact_details}
                </h3>
                <hr />
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        {contactDetailLables.map((contactDetailLable) => (
                          <TableCell key={contactDetailLable.id} align="left">
                            <b>{contactDetailLable.label}</b>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {modalOrderData ? (
                        <TableRow>
                          <TableCell align="left">
                            {modalOrderData.merchant_info.profile_name}
                          </TableCell>
                          <TableCell align="left">
                            {modalOrderData.customer_name}
                          </TableCell>
                          <TableCell align="left">
                            {getAddress(modalOrderData.pickup_address)}
                          </TableCell>
                          <TableCell align="left">
                            {getAddress(modalOrderData.delivery_address)}
                          </TableCell>
                          <TableCell align="left">
                            {modalOrderData.business_phones.length != 0
                              ? modalOrderData.business_phones.map(
                                  (businessPhoneNumber) => (
                                    <Typography key={businessPhoneNumber}>
                                      {businessPhoneNumber}
                                    </Typography>
                                  )
                                )
                              : "Not Available"}
                          </TableCell>
                          <TableCell align="left">
                            {modalOrderData.customer_phones != null &&
                            modalOrderData.customer_phones.length > 0
                              ? modalOrderData.customer_phones.map(
                                  (customerPhoneNumber) => (
                                    <Typography key={customerPhoneNumber}>
                                      {customerPhoneNumber}
                                      <IconButton
                                        onClick={() =>
                                          handleNotificationDisplayOpenModal(
                                            customerPhoneNumber
                                          )
                                        }
                                      >
                                        <MessageIcon
                                          style={{
                                            height: "16px",
                                            cursor: "pointer",
                                            color: "grey",
                                          }}
                                        />
                                      </IconButton>
                                    </Typography>
                                  )
                                )
                              : "Not Available"}
                          </TableCell>

                          <TableCell align="left">
                            {modalOrderData.delivery_distance /
                              numberForConversionInKilometers +
                              " " +
                              "km"}
                          </TableCell>
                        </TableRow>
                      ) : null}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              {modalOrderData.tp_delivery_info != null ? (
                <Box>
                  <h3 style={{ marginTop: "30px" }} id="transition-modal-title">
                    {
                      orderDataModalHeadersSubheaders.third_party_delivery_details
                    }
                  </h3>
                  <hr />
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          {thirdPartyDeliveryLabels.map(
                            (thirdPartyDeliveryLabel) => (
                              <TableCell
                                key={thirdPartyDeliveryLabel.id}
                                align="left"
                              >
                                <b>{thirdPartyDeliveryLabel.label}</b>
                              </TableCell>
                            )
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell align="left">
                            {getThirdPartDelivery(
                              modalOrderData.tp_delivery_info,
                              thirdPartyDeliveryKeys.delivery_type
                            )}
                          </TableCell>
                          <TableCell align="left">
                            {getThirdPartDelivery(
                              modalOrderData.tp_delivery_info,
                              thirdPartyDeliveryKeys.order_id
                            )}
                          </TableCell>
                          <TableCell align="left">
                            {getThirdPartDelivery(
                              modalOrderData.tp_delivery_info,
                              thirdPartyDeliveryKeys.shipment_id
                            )}
                          </TableCell>
                          <TableCell align="left">
                            {getThirdPartDelivery(
                              modalOrderData.tp_delivery_info,
                              thirdPartyDeliveryKeys.awb_code
                            )}
                          </TableCell>
                          <TableCell align="left">
                            {getThirdPartDelivery(
                              modalOrderData.tp_delivery_info,
                              thirdPartyDeliveryKeys.label_url
                            ) != constNotAvailable ? (
                              <Link
                                to={{
                                  pathname: getThirdPartDelivery(
                                    modalOrderData.tp_delivery_info,
                                    thirdPartyDeliveryKeys.label_url
                                  ),
                                }}
                                target="_blank"
                              >
                                {labelUrl}
                              </Link>
                            ) : (
                              constNotAvailable
                            )}
                          </TableCell>
                          <TableCell align="left">
                            {getThirdPartDelivery(
                              modalOrderData.tp_delivery_info,
                              thirdPartyDeliveryKeys.manifest_url
                            ) != constNotAvailable ? (
                              <Link
                                to={{
                                  pathname: getThirdPartDelivery(
                                    modalOrderData.tp_delivery_info,
                                    thirdPartyDeliveryKeys.manifest_url
                                  ),
                                }}
                                target="_blank"
                              >
                                {manifestUrl}
                              </Link>
                            ) : (
                              constNotAvailable
                            )}
                          </TableCell>
                          <TableCell align="left">
                            {getThirdPartDelivery(
                              modalOrderData.tp_delivery_info,
                              thirdPartyDeliveryKeys.invoice_url
                            ) != constNotAvailable ? (
                              <Link
                                to={{
                                  pathname: getThirdPartDelivery(
                                    modalOrderData.tp_delivery_info,
                                    thirdPartyDeliveryKeys.invoice_url
                                  ),
                                }}
                                target="_blank"
                              >
                                {invoiceUrl}
                              </Link>
                            ) : (
                              constNotAvailable
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              ) : null}
              {modalOrderData.external_contact_info != null ? (
                <Box>
                  <h3 style={{ marginTop: "30px" }} id="transition-modal-title">
                    {orderDataModalHeadersSubheaders.external_contact_info}
                  </h3>
                  <hr />
                  <Box>
                    <p>
                      <b>{orderDataModalHeadersSubheaders.name} : </b>
                      <span>
                        {getExternalInfo(
                          modalOrderData.external_contact_info,
                          externalContactKeys.name
                        )}
                      </span>
                    </p>
                  </Box>
                  <Box>
                    <p>
                      <b>{orderDataModalHeadersSubheaders.phone} : </b>
                      <span>
                        {getExternalInfo(
                          modalOrderData.external_contact_info,
                          externalContactKeys.phone
                        )}
                      </span>
                    </p>
                  </Box>
                </Box>
              ) : null}
              <Box>
                <h3 style={{ marginTop: "30px" }} id="transition-modal-title">
                  {orderDataModalHeadersSubheaders.rating}
                </h3>
                <hr />

                <Box>
                  <p>
                    <b>{orderDataModalHeadersSubheaders.da_rating} : </b>
                    <span>
                      {getRating(modalOrderData.rating, ratingKeys.da_rating)}
                    </span>
                  </p>
                </Box>
                <Box>
                  <p>
                    <b>{orderDataModalHeadersSubheaders.order_rating} : </b>
                    <span>
                      {getRating(
                        modalOrderData.rating,
                        ratingKeys.order_rating
                      )}
                    </span>
                  </p>
                </Box>
              </Box>
              <Box>
                <h3 style={{ marginTop: "30px" }} id="transition-modal-title">
                  {orderDataModalHeadersSubheaders.notes}
                </h3>
                <hr />
                <Box>
                  <p>
                    <b>{orderDataModalHeadersSubheaders.customer_note} : </b>

                    <span>
                      {modalOrderData.customer_note
                        ? modalOrderData.customer_note
                        : "Not Available"}
                    </span>
                  </p>
                </Box>
                <Box>
                  <p>
                    <b>
                      {orderDataModalHeadersSubheaders.cancellation_note} :{" "}
                    </b>
                    <span>
                      {modalOrderData.cancellation_note
                        ? modalOrderData.cancellation_note
                        : "Not Available"}
                    </span>
                  </p>
                </Box>
              </Box>
              <Box>
                <h3 style={{ marginTop: "30px" }} id="transition-modal-title">
                  {orderDataModalHeadersSubheaders.customer_note_images}
                </h3>
                <hr />
                {modalOrderData.customer_note_images ? (
                  modalOrderData.customer_note_images.length > 0 ? (
                    <TableContainer component={Paper}>
                      <Table
                        className={classes.table}
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell align="left">
                              <b>Sr No.</b>
                            </TableCell>
                            <TableCell align="left">
                              <b>Images LInk</b>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {modalOrderData.customer_note_images.map(
                            (imageUrl, index) => (
                              <TableRow key={index}>
                                <TableCell
                                  component="th"
                                  align="left"
                                  scope="row"
                                >
                                  {index + 1}
                                </TableCell>
                                <TableCell align="left">
                                  <a href={imageUrl.photo_url}>
                                    {imageUrl.photo_url}
                                  </a>
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    "Not Available"
                  )
                ) : (
                  "Not Available"
                )}
              </Box>
              <Box>
                <h3 style={{ marginTop: "30px" }} id="transition-modal-title">
                  {orderDataModalHeadersSubheaders.smart_box}
                </h3>
                <hr />
                {modalOrderData.smartbox_info != null ? (
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">
                            <span>
                              <b>
                                {
                                  orderDataModalHeadersSubheaders.smart_box_manual_delivery
                                }{" "}
                                :{" "}
                              </b>{" "}
                              {getSmartboxDelivery(
                                modalOrderData.smartbox_info,
                                smartBoxKeys.smartbox_delivery
                              )}
                            </span>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell align="left">
                            <span>
                              <b>
                                {
                                  orderDataModalHeadersSubheaders.smart_box_pretty_address
                                }{" "}
                                :{" "}
                              </b>{" "}
                              {getSmartboxDelivery(
                                modalOrderData.smartbox_info,
                                smartBoxKeys.pretty_address
                              )}
                            </span>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left">
                            <span>
                              <b>
                                {
                                  orderDataModalHeadersSubheaders.smart_box_compartment_id
                                }{" "}
                                :{" "}
                              </b>{" "}
                              {getSmartboxDelivery(
                                modalOrderData.smartbox_info,
                                smartBoxKeys.compartment_id
                              )}
                            </span>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left">
                            <span>
                              <b>
                                {orderDataModalHeadersSubheaders.smart_box_id} :{" "}
                              </b>{" "}
                              {getSmartboxDelivery(
                                modalOrderData.smartbox_info,
                                smartBoxKeys.compartment_id
                              )}
                            </span>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                  </TableContainer>
                ) : (
                  "Not Available"
                )}
              </Box>
              <Box>
                <h3 style={{ marginTop: "30px" }} id="transition-modal-title">
                  {orderDataModalHeadersSubheaders.summary}
                </h3>
                <hr />
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        {summaryLabels.map((summaryLabel) => (
                          <TableCell key={summaryLabel.id} align="left">
                            <b>{summaryLabel.label}</b>
                          </TableCell>
                        ))}
                        {modalOrderData.platform_charge > 0 ? (
                          <TableCell align="left">
                            <b>{platformCharges}</b>
                          </TableCell>
                        ) : null}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow key={modalOrderData.order_short_number}>
                        <TableCell align="left">
                          {modalOrderData.order_short_number}
                        </TableCell>
                        <TableCell align="left">
                          {convertPaisaToRupee(modalOrderData.item_total)}
                        </TableCell>
                        <TableCell align="left">
                          {convertPaisaToRupee(modalOrderData.order_total)}
                        </TableCell>
                        <TableCell align="left">
                          {convertPaisaToRupee(modalOrderData.other_charges)}
                        </TableCell>
                        <TableCell align="left">
                          {convertPaisaToRupee(modalOrderData.delivery_charges)}
                        </TableCell>
                        <TableCell align="left">
                          {convertPaisaToRupee(modalOrderData.offer_discount)}
                        </TableCell>
                        <TableCell align="left">
                          {convertPaisaToRupee(modalOrderData.product_discount)}
                        </TableCell>
                        <TableCell align="left">
                          {convertPaisaToRupee(
                            getPaymentFields(
                              modalOrderData.payment_info,
                              paymentFields.amount
                            )
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {convertPaisaToRupee(
                            getPaymentFields(
                              modalOrderData.payment_info,
                              paymentFields.refund_amount
                            )
                          )}
                        </TableCell>
                        {modalOrderData.platform_charge > 0 ? (
                          <TableCell align="left">
                            {convertPaisaToRupee(
                              modalOrderData.platform_charge
                            )}
                          </TableCell>
                        ) : null}
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}

export default OrderModal;
