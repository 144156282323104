import firebase from "firebase/app";
import "firebase/messaging";
import config from "./Utility/config.js";
import axios from "axios";

let messaging;
if (firebase.messaging.isSupported()) {
  firebase.initializeApp(config.firebaseConfig);
  messaging = firebase.messaging();
}
export const deleteFcmTokenInstance = async (authToken) => {
  if (localStorage.getItem("userAuthToken") != authToken) {
    await messaging.deleteToken();
    getClientToken();
  }
};
const postClientFcmToken = () => {
  const clientFcmToken = localStorage.getItem("userAuthToken");
  const data = {
    fcm_token: localStorage.getItem("userCurrentToken"),
    token_type: "WEB",
  };
  axios
    .post(config.postNotificationUrl, data, {
      headers: {
        Authorization: "JWT " + clientFcmToken,
      },
    })
    .then((response) => {})
    .catch((error) => {
      console.log(`fcm error is => ${error}`);
    });
};
export const getClientToken = () => {
  return messaging
    .getToken({
      vapidKey: config.firebaseVapidKey,
    })
    .then((currentToken) => {
      if (currentToken) {
        if (
          !localStorage.getItem("userCurrentToken") ||
          localStorage.getItem("userCurrentToken") !== currentToken
        ) {
          localStorage.setItem("userCurrentToken", currentToken);
          postClientFcmToken();
        }
      } else {
        alert(
          "No registration token available. Request permission to generate one."
        );
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};

export const onFcmOrderMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
