import React, { useState } from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import {
  constOrderId,
  constOrderCreatedData,
  constOrderLastUpdatedData,
  constOrderTimeElapsed,
  constOrderStatus,
  constOrderSeller,
  constOrderAmount,
  constIsONDCOrder,
  constOrderDeliveryType,
  constOrderDeliveryCharges,
  constOrderOtherCharges,
  constOrderItemTotal,
  constOrderDeliveryAgent,
  constOrderPaymentStatus,
  constOrderPaymentMode,
  constOrderPaymentId,
  constOrderCustomerName,
  constOrderCustomerNumber,
  constOrderCustomerAddress,
  constOrderLongId,
  constOrderType,
} from "../../../Utility/stringConstant";

function EyeButtonDropdown({
  eyeButonDropdownAnchorRef,
  setEyeButtonDropdownIsOpen,
  eyeButtonDropdownIsOpen,
}) {
  const handleSetStateOfTableColumnDisplay = (setStateKey) => {
    return localStorage.getItem(`${setStateKey}`) === "false" ? false : true;
  };
  const [orderIdChecked, setOrderIdChecked] = useState(
    handleSetStateOfTableColumnDisplay(constOrderId)
  );
  const [orderCreatedDateChecked, setOrderCreatedDateChecked] = useState(
    handleSetStateOfTableColumnDisplay(constOrderCreatedData)
  );
  const [orderLastUpdatedDateChecked, setOrderLastUpdatedDateChecked] =
    useState(handleSetStateOfTableColumnDisplay(constOrderLastUpdatedData));
  const [orderTimeElapsedChecked, setOrderTimeElapsedChecked] = useState(
    handleSetStateOfTableColumnDisplay(constOrderTimeElapsed)
  );
  const [ondcOrderChecked, setONDCOrderChecked] = useState(
    handleSetStateOfTableColumnDisplay(constIsONDCOrder)
  );
  const [orderStatusChecked, setOrderStatusChecked] = useState(
    handleSetStateOfTableColumnDisplay(constOrderStatus)
  );
  const [orderSellerChecked, setOrderSellerChecked] = useState(
    handleSetStateOfTableColumnDisplay(constOrderSeller)
  );
  const [orderAmountChecked, setOrderAmountChecked] = useState(
    handleSetStateOfTableColumnDisplay(constOrderAmount)
  );
  
  const [orderDeliveryTypeChecked, setOrderDeliveryTypeChecked] = useState(
    handleSetStateOfTableColumnDisplay(constOrderDeliveryType)
  );
  const [orderDeliveryChargesChecked, setOrderDeliveryChargesChecked] =
    useState(handleSetStateOfTableColumnDisplay(constOrderDeliveryCharges));
  const [orderOtherChargesChecked, setOrderOtherChargesChecked] = useState(
    handleSetStateOfTableColumnDisplay(constOrderOtherCharges)
  );
  const [orderItemTotalChecked, setOrderItemTotalChecked] = useState(
    handleSetStateOfTableColumnDisplay(constOrderItemTotal)
  );
  const [orderDeliveryAgentChecked, setOrderDeliveryAgentChecked] = useState(
    handleSetStateOfTableColumnDisplay(constOrderDeliveryAgent)
  );
  const [orderPaymentStatusChecked, setOrderPaymentStatusChecked] = useState(
    handleSetStateOfTableColumnDisplay(constOrderPaymentStatus)
  );
  const [orderPaymentModeChecked, setOrderPaymentModeChecked] = useState(
    handleSetStateOfTableColumnDisplay(constOrderPaymentMode)
  );
  const [orderPaymentIdChecked, setOrderPaymentIdChecked] = useState(
    handleSetStateOfTableColumnDisplay(constOrderPaymentId)
  );
  const [orderCustomerNameChecked, setOrderCustomerNameChecked] = useState(
    handleSetStateOfTableColumnDisplay(constOrderCustomerName)
  );
  const [orderCustomerNumberChecked, setOrderCustomerNumberChecked] = useState(
    handleSetStateOfTableColumnDisplay(constOrderCustomerNumber)
  );
  const [orderCustomerAddressChecked, setOrderCustomerAddressChecked] =
    useState(handleSetStateOfTableColumnDisplay(constOrderCustomerAddress));
  const [orderLongIdChecked, setOrderLongIdChecked] = useState(
    handleSetStateOfTableColumnDisplay(constOrderLongId)
  );
  const [orderTypeChecked, setOrderTypeChecked] = useState(
    handleSetStateOfTableColumnDisplay(constOrderType)
  );

  const tableHeaders = [
    {
      id: constOrderId,
      label: "OrderId",
      checked: orderIdChecked,
    },
    {
      id: constOrderCreatedData,
      label: "Order Created Date",
      checked: orderCreatedDateChecked,
    },
    {
      id: constOrderLastUpdatedData,
      label: "Order Last Update Date",
      checked: orderLastUpdatedDateChecked,
    },
    {
      id: constOrderTimeElapsed,
      label: "Order Time Elapsed",
      checked: orderTimeElapsedChecked,
    },
    {
      id: constOrderStatus,
      label: "Status",
      checked: orderStatusChecked,
    },
    {
      id: constOrderSeller,
      label: "Seller",
      checked: orderSellerChecked,
    },
    {
      id: constOrderAmount,
      label: "Order Amt",
      checked: orderAmountChecked,
    },
    {
      id: constIsONDCOrder,
      label: "ONDC Order",
      checked: ondcOrderChecked,
    },
    {
      id: constOrderDeliveryType,
      label: "Delivery Type",
      checked: orderDeliveryTypeChecked,
    },
    {
      id: constOrderDeliveryCharges,
      label: "Delivery Charges",
      checked: orderDeliveryChargesChecked,
    },
    {
      id: constOrderOtherCharges,
      label: "Other Charges",
      checked: orderOtherChargesChecked,
    },
    {
      id: constOrderItemTotal,
      label: "Item Total",
      checked: orderItemTotalChecked,
    },
    {
      id: constOrderDeliveryAgent,
      label: "Delivery Agent",
      checked: orderDeliveryAgentChecked,
    },
    {
      id: constOrderPaymentStatus,
      label: "Payment Status",
      checked: orderPaymentStatusChecked,
    },
    {
      id: constOrderPaymentMode,
      label: "Payment Mode",
      checked: orderPaymentModeChecked,
    },
  
    {
      id: constOrderCustomerName,
      label: "Customer Name",
      checked: orderCustomerNameChecked,
    },
    {
      id: constOrderCustomerNumber,
      label: "Customer Number",
      checked: orderCustomerNumberChecked,
    },
    {
      id: constOrderCustomerAddress,
      label: "Customer Address",
      checked: orderCustomerAddressChecked,
    },
    {
      id: constOrderLongId,
      label: "Long OrderId",
      checked: orderLongIdChecked,
    },
    {
      id: constOrderType,
      label: "Order Type",
      checked: orderTypeChecked,
    },
  ];
  const handleSetLocalStorageCheckboxesValue = (
    localStorageKey,
    stateVariable
  ) => {
    stateVariable === true
      ? localStorage.setItem(localStorageKey, "false")
      : localStorage.setItem(localStorageKey, "true");
  };
  const handleAddLocalStorageForCheckboxes = (localStorageKey) => {
    switch (localStorageKey) {
      case constOrderId:
        handleSetLocalStorageCheckboxesValue(localStorageKey, orderIdChecked);
        setOrderIdChecked(!orderIdChecked);
        break;

      case constOrderCreatedData:
        handleSetLocalStorageCheckboxesValue(
          localStorageKey,
          orderCreatedDateChecked
        );
        setOrderCreatedDateChecked(!orderCreatedDateChecked);
        break;

      case constOrderLastUpdatedData:
        handleSetLocalStorageCheckboxesValue(
          localStorageKey,
          orderLastUpdatedDateChecked
        );
        setOrderLastUpdatedDateChecked(!orderLastUpdatedDateChecked);
        break;

      case constOrderTimeElapsed:
        handleSetLocalStorageCheckboxesValue(
          localStorageKey,
          orderTimeElapsedChecked
        );
        setOrderTimeElapsedChecked(!orderTimeElapsedChecked);
        break;
        case constIsONDCOrder:
          handleSetLocalStorageCheckboxesValue(
            localStorageKey,
            ondcOrderChecked
        );
        
          setONDCOrderChecked(!ondcOrderChecked);
          break;

      case constOrderStatus:
        handleSetLocalStorageCheckboxesValue(
          localStorageKey,
          orderStatusChecked
        );
        setOrderStatusChecked(!orderStatusChecked);
        break;

      case constOrderSeller:
        handleSetLocalStorageCheckboxesValue(
          localStorageKey,
          orderSellerChecked
        );
        setOrderSellerChecked(!orderSellerChecked);
        break;

      case constOrderAmount:
        handleSetLocalStorageCheckboxesValue(
          localStorageKey,
          orderAmountChecked
        );
        setOrderAmountChecked(!orderAmountChecked);
        break;
      
       

      case constOrderDeliveryType:
        handleSetLocalStorageCheckboxesValue(
          localStorageKey,
          orderDeliveryTypeChecked
        );
        setOrderDeliveryTypeChecked(!orderDeliveryTypeChecked);
        break;

      case constOrderDeliveryCharges:
        handleSetLocalStorageCheckboxesValue(
          localStorageKey,
          orderDeliveryChargesChecked
        );
        setOrderDeliveryChargesChecked(!orderDeliveryChargesChecked);
        break;

      case constOrderOtherCharges:
        handleSetLocalStorageCheckboxesValue(
          localStorageKey,
          orderOtherChargesChecked
        );
        setOrderOtherChargesChecked(!orderOtherChargesChecked);
        break;

      case constOrderItemTotal:
        handleSetLocalStorageCheckboxesValue(
          localStorageKey,
          orderItemTotalChecked
        );
        setOrderItemTotalChecked(!orderItemTotalChecked);
        break;

      case constOrderDeliveryAgent:
        handleSetLocalStorageCheckboxesValue(
          localStorageKey,
          orderDeliveryAgentChecked
        );
        setOrderDeliveryAgentChecked(!orderDeliveryAgentChecked);
        break;

      case constOrderPaymentStatus:
        handleSetLocalStorageCheckboxesValue(
          localStorageKey,
          orderPaymentStatusChecked
        );
        setOrderPaymentStatusChecked(!orderPaymentStatusChecked);
        break;

      case constOrderPaymentMode:
        handleSetLocalStorageCheckboxesValue(
          localStorageKey,
          orderPaymentModeChecked
        );
        setOrderPaymentModeChecked(!orderPaymentModeChecked);
        break;

      case constOrderPaymentId:
        handleSetLocalStorageCheckboxesValue(
          localStorageKey,
          orderPaymentIdChecked,
          setOrderPaymentIdChecked
        );
        setOrderPaymentIdChecked(!orderPaymentIdChecked);
        break;

      case constOrderCustomerName:
        handleSetLocalStorageCheckboxesValue(
          localStorageKey,
          orderCustomerNameChecked
        );
        setOrderCustomerNameChecked(!orderCustomerNameChecked);
        break;

      case constOrderCustomerNumber:
        handleSetLocalStorageCheckboxesValue(
          localStorageKey,
          orderCustomerNumberChecked
        );
        setOrderCustomerNumberChecked(!orderCustomerNumberChecked);
        break;

      case constOrderCustomerAddress:
        handleSetLocalStorageCheckboxesValue(
          localStorageKey,
          orderCustomerAddressChecked
        );
        setOrderCustomerAddressChecked(!orderCustomerAddressChecked);
        break;

        case constOrderLongId:
        handleSetLocalStorageCheckboxesValue(
          localStorageKey,
          orderLongIdChecked
        );
        setOrderLongIdChecked(!orderLongIdChecked);
        break;

        case constOrderType:
        handleSetLocalStorageCheckboxesValue(
          localStorageKey,
          orderTypeChecked
        );
        setOrderTypeChecked(!orderTypeChecked);
        break;
    }
  };

  const handleEyeButtonDropdownClose = (event) => {
    if (
      eyeButonDropdownAnchorRef.current &&
      eyeButonDropdownAnchorRef.current.contains(event.target)
    ) {
      return;
    }

    setEyeButtonDropdownIsOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setEyeButtonDropdownIsOpen(false);
    }
  }
  return (
    <div>
      <Popper
        open={eyeButtonDropdownIsOpen}
        anchorEl={eyeButonDropdownAnchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: "1" }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "top" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleEyeButtonDropdownClose}>
                <MenuList
                  autoFocusItem={eyeButtonDropdownIsOpen}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  {tableHeaders.map((tableHeader) => (
                    <MenuItem
                      key={tableHeader.id}
                      display="flex"
                      justifyContent="space-between"
                    >
                      <Checkbox
                        checked={tableHeader.checked}
                        onChange={() =>
                          handleAddLocalStorageForCheckboxes(tableHeader.id)
                        }
                        color="primary"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                      />
                      <div>{tableHeader.label}</div>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}

export default EyeButtonDropdown;
