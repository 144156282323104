import React from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { Link } from "react-router-dom";
import { navbarHeader } from "../../Utility/stringConstant";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  navbarMenus: {
    display: "flex",
    alignItems: "center",
  },
}));
export default function Navbar({
  isNewNotificationPopped,
  onSetNewNotificationPopped,
}) {
  const classes = useStyles();
  const { circleId, authToken } = useParams();
  return (
    <div className={classes.grow}>
      <AppBar position="static" style={{ background: "#DB8F48" }}>
        <Toolbar>
          <Link
            style={{ textDecoration: "none" }}
            to={`/${{ circleId }.circleId}/${{ authToken }.authToken}`}
          >
            <Typography
              style={{ color: "#FFF" }}
              className={classes.title}
              variant="h6"
              noWrap
            >
              {navbarHeader[0]}
            </Typography>
          </Link>
          <div className={classes.search}></div>
          <div className={classes.grow} />
          <div className={classes.navbarMenus}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {/* <Link
              style={{ textDecoration: "none" }}
              to={`/${{ circleId }.circleId}/${{ authToken }.authToken}`}
            >
              <Typography
                style={{ color: "#FFF" }}
                className={classes.title}
                variant="h6"
                noWrap
              >
                {navbarHeader[1]}
              </Typography>
            </Link>
            <Link
              style={{ textDecoration: "none" }}
              to={`/${{ circleId }.circleId}/${
                { authToken }.authToken
              }/queries`}
            >
              <Typography
                style={{ color: "#FFF", margin: "0 24px" }}
                className={classes.title}
                variant="h6"
                noWrap
              >
                {navbarHeader[2]}
              </Typography>
            </Link> */}
              {/* <Link
                to={`/${{ circleId }.circleId}/${
                  { authToken }.authToken
                }/notification`}
              >
                <IconButton
                  style={{ color: "#FFF", marginLeft: "16px" }}
                  aria-label="show 17 new notifications"
                  color="inherit"
                  onClick={() => onSetNewNotificationPopped(true)}
                >
                  <Badge
                    variant="dot"
                    invisible={isNewNotificationPopped}
                    color="secondary"
                  >
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
              </Link> */}
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}
