/* eslint-disable no-use-before-define */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { blockStyle } from "../../../Utility/Style";
import SearchIcon from "@material-ui/icons/Search";
import { DebounceInput } from "react-debounce-input";

const useStyles = makeStyles(blockStyle);

export default function InputBox({
  onValueChange,
  textValue,
  placeholder = "Search...",
}) {
  const classes = useStyles();

  return (
    <div className={classes.inputTextField}>
      <DebounceInput
        style={{ border: "none", outline: "none" }}
        className={classes.searchInputField}
        onChange={onValueChange}
        value={textValue}
        debounceTimeout={200}
        placeholder={placeholder}
      />
      <SearchIcon className={classes.inputSearch} />
    </div>
  );
}
