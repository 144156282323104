import React, { useState } from "react";
import { MultiSelect } from "react-multi-select-component";

const Filters = ({ filterUtility, handleFilterChange }) => {
  const [selected, setSelected] = useState([]);
  const handleChange = (e) => {
    setSelected(Array.isArray(e) ? e.map((x) => x.value) : []);
    handleFilterChange(e);
  };
  return (
    <div>
      <MultiSelect
        options={filterUtility}
        value={filterUtility.filter((obj) => selected.includes(obj.value))}
        onChange={handleChange}
        labelledBy="Select"
      />
    </div>
  );
};

export default Filters;
