import "date-fns";
import React from "react";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  filterModifiedDate,
  filterCreatedDate,
  datePickerOr,
} from "../../../Utility/stringConstant";

export default function DatePicker({
  onHandleDateFromChange,
  onHandleDateToChange,
  onHandleDateFromChangeCreated,
  onHandleDateToChangeCreated,
  toDate,
  fromDate,
  toDateCreated,
  fromDateCreated,
  clickedRefFrom,
  clickedRefTo,
  clickedRefFromCreated,
  clickedRefToCreated,
}) {
  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="row"
      justifyContent="center"
    >
      <Box style={{ margin: "0 40px" }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <h5>{filterModifiedDate}</h5>
          <Grid container direction="column">
            <div>
              <KeyboardDatePicker
                maxDate={new Date()}
                disabled={fromDateCreated !== null ? true : false}
                autoOk={true}
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="date-picker-inline-updated"
                label="Orders From"
                value={clickedRefFrom.current ? fromDate : null}
                onChange={onHandleDateFromChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </div>
            <div>
              <KeyboardDatePicker
                disabled={fromDate === null ? true : false}
                minDate={new Date(fromDate)}
                maxDate={new Date()}
                autoOk={true}
                variant="inline"
                margin="normal"
                id="date-picker-dialog-updated"
                label="Orders To"
                format="dd/MM/yyyy"
                value={clickedRefTo.current ? toDate : null}
                onChange={onHandleDateToChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </div>
          </Grid>
        </MuiPickersUtilsProvider>
      </Box>
      <Typography>{datePickerOr}</Typography>
      <Box style={{ margin: "0 40px" }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <h5>{filterCreatedDate}</h5>
          <Grid item xs={12} container direction="column">
            <div>
              <KeyboardDatePicker
                maxDate={new Date()}
                disabled={fromDate !== null ? true : null}
                autoOk={true}
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="date-picker-inline-created"
                label="Orders From"
                value={clickedRefFromCreated.current ? fromDateCreated : null}
                onChange={onHandleDateFromChangeCreated}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </div>
            <div>
              <KeyboardDatePicker
                disabled={fromDateCreated === null ? true : false}
                minDate={new Date(fromDateCreated)}
                maxDate={new Date()}
                autoOk={true}
                variant="inline"
                margin="normal"
                id="date-picker-dialog-created"
                label="Orders To"
                format="dd/MM/yyyy"
                value={clickedRefToCreated.current ? toDateCreated : null}
                onChange={onHandleDateToChangeCreated}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </div>
          </Grid>
        </MuiPickersUtilsProvider>
      </Box>
    </Box>
  );
}
