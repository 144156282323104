import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Pagination } from "@material-ui/lab";
import Box from "@material-ui/core/Box";
import Navbar from "../Navbar/Navbar";
import axios from "axios";
import { queriesTableColumns } from "../../Utility/filterList";
import { blockStyle } from "../../Utility/Style";
import config from "../../Utility/config";
import {
  getCustomerAddress,
  getDateFormat,
  convertPaisaToRupee,
  epochDateConversionToUtcDateAndTime,
  removeUnderscoreConvertInSmallcase,
  decreaseCartIdLength,
} from "../../Utility/paramsConvert";
import { numberForConversionInKilometers } from "../../Utility/stringConstant";
import EnquiryModal from "./EnquiryModal";

const useStyles = makeStyles(blockStyle);
function Queries({ isNewNotificationPopped, onSetNewNotificationPopped }) {
  const classes = useStyles();
  const { circleId, authToken } = useParams();
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(1);
  const [orderCount, setorderCount] = useState(0);
  const [orderDataDisplayModalOpen, setOrderDataDisplayModalOpen] =
    useState(false);
  const [modalOrderData, setModalOrderData] = useState("");

  const changePage = (event, value) => {
    setPage(value);
  };

  const handleOpenModalWithEnquiryData = (item) => {
    setModalOrderData(item);
    setOrderDataDisplayModalOpen(true);
  };

  const handleCloseModalWithOrderDetail = () => {
    setOrderDataDisplayModalOpen(false);
  };
  const handlegetQueriesData = () => {
    axios
      .get(config.getOndcEnquiryCart, {
        headers: {
          Authorization: "JWT " + { authToken }.authToken,
        },
        params: {
          cluster_id: { circleId }.circleId,
          page: page,
        },
      })
      .then((response) => {
        setTableData(response.data.results);
        setorderCount(response.data.count);
      })
      .catch(function (error) {
        alert("Unauthorize or slow internet");
      });
  };
  const rows = tableData.map((item, key) => {
    return {
      id: decreaseCartIdLength(item.cart_id),
      ondc_enquiry_timeout: epochDateConversionToUtcDateAndTime(
        item.ondc_enquiry_timeout
      ),
      ondc_enquiry_status: removeUnderscoreConvertInSmallcase(
        item.ondc_enquiry_status
      ),
      Order_created_date: getDateFormat(item.created),
      last_update: getDateFormat(item.modified),
      item_total: convertPaisaToRupee(item.item_total),
      other_charges: convertPaisaToRupee(item.other_charges),
      delivery_charges: convertPaisaToRupee(item.delivery_charges),
      offer_discount: convertPaisaToRupee(item.offer_discount),
      order_total: convertPaisaToRupee(item.order_total),
      business_name: item.business_name,
      pickup_address: getCustomerAddress(item.pickup_address),
      Customer_address: getCustomerAddress(item.delivery_address),
      delivery_distance: item.delivery_distance,
      item_data: item,
    };
  });
  useEffect(() => {
    handlegetQueriesData();
  }, [page]);
  return (
    <>
      <Navbar
        isNewNotificationPopped={isNewNotificationPopped}
        onSetNewNotificationPopped={onSetNewNotificationPopped}
      />
      <EnquiryModal
        orderDataDisplayModalOpen={orderDataDisplayModalOpen}
        modalQueryData={modalOrderData}
        onHandleCloseModalWithOrderDetail={handleCloseModalWithOrderDetail}
      />
      <div className={classes.root}>
        <Box style={{ marginTop: "0em" }}>
          <Box spacing={3}>
            <Box>
              <TableContainer
                component={Paper}
                style={{
                  height: "100%",
                  width: "100%",
                  display: "block",
                  overflow: "auto",
                }}
              >
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {queriesTableColumns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                          }}
                        >
                          <strong>{column.label}</strong>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => {
                      return row ? (
                        <TableRow key={row.id}>
                          <TableCell
                            onClick={() =>
                              handleOpenModalWithEnquiryData(row.item_data)
                            }
                            style={{
                              cursor: "pointer",
                              color: "#0000ff",
                            }}
                            align="left"
                          >
                            <strong>{row.id}</strong>
                          </TableCell>
                          <TableCell align="left">
                            {row.ondc_enquiry_timeout}
                          </TableCell>
                          <TableCell align="left">
                            {row.ondc_enquiry_status}
                          </TableCell>
                          <TableCell align="left">
                            {row.Order_created_date}
                          </TableCell>
                          <TableCell align="left">{row.last_update}</TableCell>
                          <TableCell align="left">{row.item_total}</TableCell>
                          <TableCell align="left">
                            {row.other_charges}
                          </TableCell>
                          <TableCell align="left">
                            {row.delivery_charges}
                          </TableCell>
                          <TableCell align="left">
                            {row.offer_discount}
                          </TableCell>
                          <TableCell align="left">{row.order_total}</TableCell>
                          <TableCell align="left">
                            {row.business_name}
                          </TableCell>
                          <TableCell align="left">
                            {row.pickup_address}
                          </TableCell>
                          <TableCell align="left">
                            {row.Customer_address}
                          </TableCell>
                          <TableCell align="left">
                            {row.delivery_distance /
                              numberForConversionInKilometers +
                              " " +
                              "km"}
                          </TableCell>
                        </TableRow>
                      ) : null;
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box display="flex" justifyContent="center">
                <Pagination
                  style={{
                    paddingTop: "20px",
                    scrollPaddingBottom: "20px",
                    alignContent: "center",
                  }}
                  count={Math.ceil(orderCount / 20)}
                  page={parseInt(page)}
                  onChange={changePage}
                  showFirstButton
                  showLastButton
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </div>
    </>
  );
}

export default Queries;
