export const orderDetails = [
    {
        id: "order_short_number",
    label: "Order ID",
    minWidth: 50,
    align: "left",
    },
    // {
    //     id: "business_id",
    // label: "Business ID",
    // minWidth: 50,
    // align: "left",
    // },
    // {
    //     id: "order_short_number",
    // label: "Order Short Number",
    // minWidth: 50,
    // align: "left",
    // },
    {
        id: "status",
    label: "Status",
    minWidth: 50,
    align: "left",
    },
    {
        id: "date_created",
    label: "Date Created",
    minWidth: 50,
    align: "left",
    },
    {
        id: "date_created_gmt",
    label: "Date Created GMT",
    minWidth: 50,
    align: "left",
    },
    {
        id: "date_modified",
    label: "Date Modified",
    minWidth: 50,
    align: "left",
    },
    {
        id: "date_modified_gmt",
    label: "Date Modified GMT",
    minWidth: 50,
    align: "left",
    },
]

export const shippingDetails = [
    {
        id: "add",
    label: "Address",
    minWidth: 50,
    align: "left",
    },
    {
        id: "address_type",
    label: "Address Type",
    minWidth: 50,
    align: "left",
    },
    {
        id: "city",
    label: "City",
    minWidth: 50,
    align: "left",
    },
    // {
    //     id: "cod_amount",
    // label: "COD amount",
    // minWidth: 50,
    // align: "left",
    // },
    {
        id: "country",
    label: "Country",
    minWidth: 50,
    align: "left",
    },
    // {
    //     id: "hsn_code",
    // label: "hsn code",
    // minWidth: 50,
    // align: "left",
    // },
    {
        id: "name",
    label: "Full Name",
    minWidth: 50,
    align: "left",
    },
    // {
    //     id: "order",
    // label: "Short order id",
    // minWidth: 50,
    // align: "left",
    // },
    // {
    //     id: "order_date",
    // label: "order date",
    // minWidth: 50,
    // align: "left",
    // },
    {
        id: "payment_mode",
    label: "Payment mode",
    minWidth: 50,
    align: "left",
    },
    {
        id: "phone",
    label: "Phone",
    minWidth: 50,
    align: "left",
    },
    {
        id: "pin",
    label: "Pin",
    minWidth: 50,
    align: "left",
    },
    {
        id: "products_desc",
    label: "Product description",
    minWidth: 50,
    align: "left",
    },
    // {
    //     id: "quantity",
    // label: "quantity",
    // minWidth: 50,
    // align: "left",
    // },
    // {
    //     id: "return_add",
    // label: "return address",
    // minWidth: 50,
    // align: "left",
    // },
    // {
    //     id: "return_city",
    // label: "return city",
    // minWidth: 50,
    // align: "left",
    // },
    // {
    //     id: "return_country",
    // label: "return country",
    // minWidth: 50,
    // align: "left",
    // },
    // {
    //     id: "return_phone",
    // label: "return phone",
    // minWidth: 50,
    // align: "left",
    // },
    // {
    //     id: "return_pin",
    // label: "return pin",
    // minWidth: 50,
    // align: "left",
    // },
    // {
    //     id: "return_state",
    // label: "return state",
    // minWidth: 50,
    // align: "left",
    // },
    // {
    //     id: "seller_add",
    // label: "seller address",
    // minWidth: 50,
    // align: "left",
    // },
    // {
    //     id: "seller_gst_tin",
    // label: "seller gst tin",
    // minWidth: 50,
    // align: "left",
    // },
    // {
    //     id: "seller_inv",
    // label: "seller invoice",
    // minWidth: 50,
    // align: "left",
    // },
    // {
    //     id: "seller_name",
    // label: "seller name",
    // minWidth: 50,
    // align: "left",
    // },
    // {
    //     id: "shipment_height",
    // label: "shipment height",
    // minWidth: 50,
    // align: "left",
    // },
    // {
    //     id: "shipment_width",
    // label: "shipment width",
    // minWidth: 50,
    // align: "left",
    // },
    // {
    //     id: "shipment_mode",
    // label: "shipment mode",
    // minWidth: 50,
    // align: "left",
    // },
    {
        id: "state",
    label: "State",
    minWidth: 50,
    align: "left",
    },
    {
        id: "total_amount",
    label: "Total Amount",
    minWidth: 50,
    align: "left",
    },
    // {
    //     id: "waybill",
    // label: "waybill",
    // minWidth: 50,
    // align: "left",
    // },
    // {
    //     id: "weight",
    // label: "weight",
    // minWidth: 50,
    // align: "left",
    // },
]
export const pickupDetails = [
    {
        id: "name",
        label: "Name",
        minWidth: 50,
        align:"left"
    },
    {
        id: "add",
        label: "Address",
        minWidth: 50,
        align:"left",
    },
    {
        id: "city",
        label: "City",
        minWidth: 50,
        align:"left"
    },
    {
        id: "pin_code",
        label: "Pin Code",
        minWidth: 50,
        align:"left"
    },
    {
        id: "country",
        label: "Country",
        minWidth: 50,
        align:"left"
    },
    {
        id: "phone",
        label: "Phone",
        minWidth: 50,
        align:"left"
    }
]