import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import { makeStyles, Typography } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";

import Navbar from "../Navbar/Navbar";
import NotificationCard from "./NotificationCard";
import { useLocation } from "react-router";
import axios from "axios";
import config from "../../Utility/config";

const useStyles = makeStyles({
  notifications: {
    margin: "20px 0",
  },
  activity: {
    textAlign: "left",
    marginTop: "20px",
    fontWeight: 700,
  },
  activityTitle: {
    fontWeight: 700,
  },
  activityNew: {
    fontWeight: 700,
  },
});
function Notification({ isNewNotificationPopped, onSetNewNotificationPopped }) {
  const classes = useStyles();
  const location = useLocation();
  const renderedPageLocation = location.pathname.split("/").pop();
  const { circleId, authToken } = useParams();
  const [page, setPage] = useState(1);
  const [allNotifications, setAllNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);

  const changePage = (event, value) => {
    setPage(value);
  };

  const handleGetAllNotification = () => {
    axios
      .get(config.getNotificationListUrl, {
        headers: {
          Authorization: "JWT " + { authToken }.authToken,
        },
        params: {
          page: page,
          cluster_id: { circleId }.circleId,
        },
      })
      .then((response) => {
        setAllNotifications(response.data.results);
        setNotificationCount(response.data.count);
      })
      .catch(function (error) {
        alert("Unauthorize or slow internet");
      });
  };

  useEffect(() => {
    if (
      !localStorage.getItem("userAuthToken") ||
      localStorage.getItem("userAuthToken") != { authToken }.authToken
    ) {
      localStorage.setItem("userAuthToken", { authToken }.authToken);
    }
    handleGetAllNotification();
  }, [page]);

  useEffect(() => {
    if (renderedPageLocation === "notification") {
      onSetNewNotificationPopped(true);
    }
    handleGetAllNotification();
  }, [isNewNotificationPopped]);

  return (
    <>
      <Navbar
        isNewNotificationPopped={isNewNotificationPopped}
        onSetNewNotificationPopped={onSetNewNotificationPopped}
      />
      <Container>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          className={classes.activity}
        >
          <Box>
            <Typography className={classes.activityTitle} variant="h6">
              All Activity
            </Typography>
          </Box>
        </Box>
        <hr />
        <div className={classes.notifications}>
          {allNotifications
            ? allNotifications.map((notification, index) => {
                return (
                  <NotificationCard key={index} notification={notification} />
                );
              })
            : null}
        </div>
      </Container>
      <Box display="flex" justifyContent="center">
        <Pagination
          style={{
            paddingTop: "20px",
            scrollPaddingBottom: "20px",
            alignContent: "center",
          }}
          count={Math.ceil(notificationCount / 20)}
          page={parseInt(page)}
          onChange={changePage}
          showFirstButton
          showLastButton
        />
      </Box>
    </>
  );
}

export default Notification;
