import React, { useRef, useState, useEffect } from 'react';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar } from '@material-ui/core';
import { BootstrapButton } from "../../../Utility/Style";
import axios from "axios";

const WarehouseAddressForm = ({ authToken }) => {
  const anchorElRef = useRef(null);

  
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false); 
  const [isOpen, setIsOpen] = useState(false);  

 
  const schema = yup.object().shape({
    city: yup.string().required('City is required'),
    name: yup.string().required('Name is required'),
    phone: yup.string().required('Phone is required').matches(/^\+?[1-9]\d{9,14}$/, 'Phone number is not valid'),
    address: yup.string().required('Address is required'),
    country: yup.string().required('Country is required'),
    pin_code: yup.number().required('Pin Code is required').positive().integer(),
  });

 
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      city: '',
      name: '',
      phone: '',
      address: '',
      country: '',
      pin_code: '',
    }
  });

  
  const handleFormSubmit = async (data) => {
    setSnackbarOpen(false); 
    setIsSubmitting(true); 
    try {
      await axios.post(
        `https://api.nest.esamudaay.com/api/v1/delhivery/pickup/`,
        data,
        {
          headers: {
            Authorization: "JWT " + authToken,
          }
        }
      );
      setSnackbarMessage('Warehouse Address added successfully');
      setSnackbarOpen(true);
      setIsOpen(false); 
      reset(); 
    } catch (error) {
      console.error(error.response?.data || error.message);
      setSnackbarMessage('Failed to add Warehouse Address. Please try again.');
      setSnackbarOpen(true);
    } finally {
      setIsSubmitting(false); 
    }
  };

  
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const warehouseAddressButtonStyle = {
    addButton: {
      backgroundColor: "#DB8F48",
      borderColor: "#d0833b",
      color: "#fff",
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
  };

 
  const saveButtonOnClick = handleSubmit(handleFormSubmit);  

  return (
    <div>
      <BootstrapButton
        variant="contained"
        sx={{ backgroundColor: "#DB8F48" }}
        className={warehouseAddressButtonStyle.addButton}
        onClick={() => setIsOpen(true)}  
        ref={anchorElRef}
      >
        Add New Warehouse Address
      </BootstrapButton>

      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>Add New Warehouse Address</DialogTitle>
        <DialogContent>
          <form id="warehouseAddressForm">
            <Controller
              name="city"
              control={control}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  label="City"
                  fullWidth
                  {...field}
                  error={!!errors.city}
                  helperText={errors.city?.message}
                />
              )}
            />

            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  label="Name"
                  fullWidth
                  {...field}
                  error={!!errors.name}
                  helperText={errors.name?.message}
                />
              )}
            />

            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  label="Phone"
                  type="tel"
                  fullWidth
                  {...field}
                  error={!!errors.phone}
                  helperText={errors.phone?.message}
                />
              )}
            />

            <Controller
              name="address"
              control={control}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  label="Address"
                  multiline
                  rows={4}
                  fullWidth
                  {...field}
                  error={!!errors.address}
                  helperText={errors.address?.message}
                />
              )}
            />

            <Controller
              name="country"
              control={control}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  label="Country"
                  fullWidth
                  {...field}
                  error={!!errors.country}
                  helperText={errors.country?.message}
                />
              )}
            />

            <Controller
              name="pin_code"
              control={control}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  label="Pin Code"
                  type="number"
                  fullWidth
                  {...field}
                  error={!!errors.pin_code}
                  helperText={errors.pin_code?.message}
                />
              )}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)} color="primary">
            Cancel
          </Button>
          <Button 
            onClick={saveButtonOnClick} 
            color="primary"
            disabled={isSubmitting}  
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for success/error messages */}
      <Snackbar
        open={snackbarOpen}
        message={snackbarMessage}
        autoHideDuration={5000}  
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}  
      />
    </div>
  );
};

export default WarehouseAddressForm;