import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { UploadImagesData } from "./Components/UploadImagesData.jsx";
import axios from "axios";
import config from "../../Utility/config";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import {
  uploadProductImagesLabels,
  uploadImagesCategoryFilterConstants,
} from "../../Utility/stringConstant";
import { Pagination } from "@material-ui/lab";
import { Container } from "@material-ui/core";
import InputBox from "../Homepage/HomepageComponent/InputBox";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  underline: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
});
function UploadImages() {
  const classes = useStyles();
  const [selectedBusinessId, setSelectedBusinessId] = useState(null);
  const [tableData, setTableData] = useState([]);
  const orderStatusRef = useRef(null);
  const [orderCount, setorderCount] = useState(0);
  const [page, setPage] = useState(1);
  const { circleId, authToken, businessId } = useParams();
  const [businessCategories, setBusinessCategories] = useState([]);
  const [categoryId, setCategoryId] = useState(null);
  const [textValueProduct, setTextValueProduct] = useState("");
  const [businessSubCategories, setBusinessSubCategories] = useState([]);
  const [businessSubCategoriesId, setBusiessSubCategoriesId] = useState(null);
  const [businessDetails, setBusinessDetails] = useState(null);
  const didMountBusinessCategories = useRef(false);
  const didMountBusinessProductsSubCategorywise = useRef(false);
  const didMountBusinessProductsByName = useRef(false);
  const didMountBusinessSubCategory = useRef(false);

  const handleValueChangeProduct = (e) => {
    setTextValueProduct(e.target.value);
  };
  const handleChangeSubCategory = (event) => {
    setBusiessSubCategoriesId(event.target.value);
    setTextValueProduct("");
    setPage(1);
  };
  const handleChangeCategoryId = (event) => {
    setCategoryId(event.target.value);
    setTextValueProduct("");
  };
  const changePage = (event, value) => {
    setPage(value);
  };

  const handleGetBusinessDetails = () => {
    axios
      .get(config.getBusinessUrl + selectedBusinessId, {
        headers: {
          Authorization: "JWT " + { authToken }.authToken,
        },
      })
      .then((response) => {
        setBusinessDetails(response.data);
      })
      .catch(function (error) {
        console.log(`an error occured ${error}`);
      });
  };

  const handleGetBusinessCategories = () => {
    axios
      .get(config.getBusinessUrl + selectedBusinessId + "/catalog/categories", {
        headers: {
          Authorization: "JWT " + { authToken }.authToken,
        },
      })
      .then((response) => {
        setBusinessCategories(response.data.categories);
      })
      .catch(function (error) {
        console.log(`an error occured ${error}`);
      });
  };

  const handleGetBusinessSubCategories = () => {
    axios
      .get(config.getBusinessUrl + selectedBusinessId + "/catalog/categories", {
        headers: {
          Authorization: "JWT " + { authToken }.authToken,
        },
        params: {
          parent_category_id: categoryId,
        },
      })
      .then((response) => {
        setBusinessSubCategories(response.data.categories);
      })
      .catch(function (error) {
        console.log(`an error occured ${error}`);
      });
  };

  const handleGetAllBusinessProducts = () => {
    axios
      .get(config.getBusinessUrl + selectedBusinessId + "/catalog/products", {
        headers: {
          Authorization: "JWT " + { authToken }.authToken,
        },
        params: {
          page: page,
        },
      })
      .then((response) => {
        setTableData(response.data.results);
        setorderCount(response.data.count);
      })
      .catch(function (error) {
        console.log(`an error occured ${error}`);
      });
  };
  const handleGetAllBusinessProductsSubCategoryWise = (categoryId) => {
    axios
      .get(
        config.getBusinessUrl +
          selectedBusinessId +
          "/catalog/categories/" +
          categoryId +
          "/products",
        {
          headers: {
            Authorization: "JWT " + { authToken }.authToken,
          },
          params: {
            page: page,
          },
        }
      )
      .then((response) => {
        setTableData(response.data.results);
        setorderCount(response.data.count);
      })
      .catch(function (error) {
        console.log(`an error occured ${error}`);
      });
  };
  function handleGetProductsByName() {
    axios
      .get(config.getBusinessUrl + selectedBusinessId + "/catalog/products", {
        headers: {
          Authorization: "JWT " + { authToken }.authToken,
        },
        params: {
          filter: textValueProduct,
        },
      })
      .then((response) => {
        setTableData(response.data.results);
        setorderCount(response.data.count);
        setBusiessSubCategoriesId(null);
        setCategoryId(null);
      })
      .catch(function (error) {
        console.log(`an error occured ${error}`);
      });
  }
  useEffect(() => {
    if (didMountBusinessProductsByName.current === true) {
      handleGetProductsByName();
    } else {
      didMountBusinessProductsByName.current = true;
    }
  }, [textValueProduct]);

  useEffect(() => {
    if (
      didMountBusinessProductsSubCategorywise.current === true &&
      businessSubCategoriesId !== null
    ) {
      handleGetAllBusinessProductsSubCategoryWise(businessSubCategoriesId);
    } else {
      didMountBusinessProductsSubCategorywise.current = true;
    }
  }, [businessSubCategoriesId]);

  useEffect(() => {
    if (didMountBusinessSubCategory.current === true && categoryId !== null) {
      handleGetBusinessSubCategories();
    } else {
      didMountBusinessSubCategory.current = true;
    }
  }, [categoryId]);

  useEffect(() => {
    if (selectedBusinessId !== null) {
      handleGetAllBusinessProducts();
      handleGetBusinessDetails();
    }
    if (didMountBusinessCategories.current === true) {
      handleGetBusinessCategories();
    } else {
      didMountBusinessCategories.current = true;
    }
  }, [selectedBusinessId]);

  useEffect(() => {
    if (selectedBusinessId !== null) {
      if (categoryId !== null) {
        handleGetAllBusinessProductsSubCategoryWise(businessSubCategoriesId);
      } else {
        handleGetAllBusinessProducts();
      }
    }
  }, [page]);

  useEffect(() => {
    setSelectedBusinessId({ businessId }.businessId);
  }, []);

  const rows = tableData.map((item, key) => {
    return {
      id: item.product_id,
      product_name: item.product_name,
      skus: item.skus,
      product_images: item.images,
    };
  });

  return (
    <>
      <Container>
        <h3 style={{ textDecoration: "underline" }}>
          {businessDetails !== null ? businessDetails.business_name : null}
        </h3>
        <Box
          display="flex"
          flexWrap="wrap"
          style={{ margin: "30px 0", justifyContent: "center" }}
        >
          <Box style={{ display: "flex" }}>
            {businessId !== "" ? (
              <Box style={{ margin: "0 5px" }} ref={orderStatusRef}>
                <h5>{uploadImagesCategoryFilterConstants.categories}</h5>
                <FormControl variant="filled" className={classes.formControl}>
                  <Select
                    native
                    onChange={handleChangeCategoryId}
                    style={{
                      border: "1px solid #CCCCCC",
                      background: "#ffffff",
                      borderRadius: "10px",
                      textDecoration: "none",
                    }}
                    inputProps={{
                      name: "Business Catagories",
                      id: "filled-age-native-simple",
                    }}
                    defaultValue="none"
                    disableUnderline
                    value={textValueProduct !== "" ? "none" : categoryId}
                  >
                    <option aria-label="None" value="">
                      {uploadImagesCategoryFilterConstants.selectCategory}
                    </option>
                    {businessCategories !== null ||
                    businessCategories !== "undefined"
                      ? businessCategories.map((businessCategory) => {
                          if (businessCategory.parent_category_id === null) {
                            return (
                              <option
                                key={businessCategory.category_id}
                                value={businessCategory.category_id}
                              >
                                {businessCategory.category_name}
                              </option>
                            );
                          }
                        })
                      : null}
                  </Select>
                </FormControl>
              </Box>
            ) : null}
            {businessSubCategories.length >= 0 ? (
              <Box style={{ margin: "0 5px" }} ref={orderStatusRef}>
                <h5>{uploadImagesCategoryFilterConstants.subCategories}</h5>
                <FormControl variant="filled" className={classes.formControl}>
                  <Select
                    native
                    onChange={handleChangeSubCategory}
                    style={{
                      border: "1px solid #CCCCCC",
                      background: "#ffffff",
                      borderRadius: "10px",
                      textDecoration: "none",
                    }}
                    inputProps={{
                      name: "Business Sub-Categories",
                      id: "filled-age-native-simple",
                    }}
                    defaultValue="none"
                    disableUnderline
                    value={
                      textValueProduct !== "" ? "none" : businessSubCategoriesId
                    }
                  >
                    <option aria-label="None" value="">
                      {uploadImagesCategoryFilterConstants.selectSubCategories}
                    </option>
                    {businessSubCategories.map((subCategory) => {
                      return (
                        <option
                          key={subCategory.category_id}
                          value={subCategory.category_id}
                        >
                          {subCategory.category_name}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
            ) : null}
          </Box>
        </Box>
        <Box style={{ margin: "0 0 20px 0" }}>
          <InputBox
            onValueChange={handleValueChangeProduct}
            textValue={textValueProduct}
            id="order-short-number-search"
            placeholder="Search by Product name..."
          />
        </Box>
        <Box style={{ marginTop: "0em" }}>
          <Box spacing={3}>
            <Box>
              <TableContainer
                component={Paper}
                style={{
                  height: "100%",
                  width: "100%",
                  display: "block",
                  overflow: "auto",
                }}
              >
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {uploadProductImagesLabels.map((column) => (
                        <TableCell
                          key={column.id}
                          align="left"
                          style={{ borderLeft: "1px solid #E0E0E0" }}
                        >
                          <strong>{column.label}</strong>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <UploadImagesData rows={rows} businessId={businessId} />
                  </TableBody>
                </Table>
              </TableContainer>
              <Box display="flex" justifyContent="center">
                <Pagination
                  style={{
                    paddingTop: "20px",
                    scrollPaddingBottom: "20px",
                    alignContent: "center",
                  }}
                  count={Math.ceil(orderCount / 20)}
                  page={parseInt(page)}
                  onChange={changePage}
                  showFirstButton
                  showLastButton
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
}

export default UploadImages;
