import React from "react";
import Snackbar from "@material-ui/core/Snackbar";

function NotificationSnackbar({
  notificationBody,
  showNotificationSnackbar,
  onHandleCloseNotificationSnackbar,
}) {
  const vertical = "top";
  const horizontal = "right";
  return (
    <>
      <Snackbar
        style={{ top: "80px" }}
        anchorOrigin={{ vertical, horizontal }}
        open={showNotificationSnackbar}
        onClose={onHandleCloseNotificationSnackbar}
        message={notificationBody}
        key={vertical + horizontal}
      />
    </>
  );
}

export default NotificationSnackbar;
