import React from "react";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";
import Backdrop from "@material-ui/core/Backdrop";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { blockStyle } from "../../Utility/Style";
import {
  enquiryOtherChargesHeader,
  enquiryOfferHeader,
  enquiryCartHeader,
} from "../../Utility/filterList";

import { convertPaisaToRupee } from "../../Utility/paramsConvert";

import { enquiryModalHeader, enquiryModal } from "../../Utility/stringConstant";

const useStyles = makeStyles(blockStyle);
function EnquiryModal({
  orderDataDisplayModalOpen,
  modalQueryData,
  onHandleCloseModalWithOrderDetail,
}) {
  const classes = useStyles();
  return (
    <Modal
      style={{ width: "100%" }}
      //   key={modalOrderData.order_id}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={orderDataDisplayModalOpen}
      onClose={onHandleCloseModalWithOrderDetail}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={orderDataDisplayModalOpen}>
        <div className={classes.modalOuter}>
          <div className={classes.modalPaper}>
            <Box display="flex" justifyContent="flex-end">
              <IconButton onClick={onHandleCloseModalWithOrderDetail}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box>
              <h3>{enquiryModalHeader.enquiry_order}</h3>
              <hr />
              <Box item xs={6} md={6} style={{ display: "flex" }}>
                <p>
                  <strong>{enquiryModal.cartId}</strong>
                </p>
                <p style={{ marginLeft: "20px" }}>{modalQueryData.cart_id}</p>
              </Box>
            </Box>
            <Box>
              <Box>
                <p>
                  <strong>{enquiryModalHeader.items_details}</strong>
                </p>
              </Box>
              <Box>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        {enquiryCartHeader.map((cartHeader) => (
                          <TableCell key={cartHeader.id} align="left">
                            <b>{cartHeader.label}</b>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {modalQueryData.cart_items
                        ? modalQueryData.cart_items.map((cartItem) => (
                            <TableRow key={cartItem.sku_id}>
                              <TableCell align="left">
                                {cartItem.product_name}
                              </TableCell>
                              <TableCell align="left">
                                {cartItem.properties
                                  ? cartItem.properties.quant.quant
                                  : null}
                              </TableCell>
                              <TableCell align="left">
                                {cartItem.properties
                                  ? cartItem.properties.quant.unit
                                  : null}
                              </TableCell>
                              <TableCell align="left">
                                {cartItem.unit_price}
                              </TableCell>
                              <TableCell align="left">
                                {cartItem.unit_price_with_addons}
                              </TableCell>
                              <TableCell align="left">
                                {cartItem.addons.map((addon) => (
                                  <Typography>
                                    {addon.addon_name +
                                      "," +
                                      addon.price +
                                      "," +
                                      addon.addon_list_name}
                                  </Typography>
                                ))}
                              </TableCell>
                            </TableRow>
                          ))
                        : null}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
            <Box>
              <Box>
                <p>
                  <strong>{enquiryModalHeader.other_charges}</strong>
                </p>
              </Box>
              <Box>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        {enquiryOtherChargesHeader.map((otherChargesHeader) => (
                          <TableCell key={otherChargesHeader} align="left">
                            <b>{otherChargesHeader}</b>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {modalQueryData
                        ? modalQueryData.other_charges_detail.map((charge) => (
                            <TableRow key={charge.name}>
                              <TableCell align="left">{charge.name}</TableCell>
                              <TableCell align="left">
                                {convertPaisaToRupee(charge.value)}
                              </TableCell>
                            </TableRow>
                          ))
                        : null}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
            <Box>
              <Box>
                <p>
                  <strong>{enquiryModalHeader.offer_details}</strong>
                </p>
              </Box>
              <Box>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        {enquiryOfferHeader.map((offerHeader) => (
                          <TableCell key={offerHeader.id} align="left">
                            <b>{offerHeader.label}</b>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {modalQueryData.offer_info ? (
                        <TableRow key={modalQueryData.cart_id}>
                          <TableCell align="left">
                            {modalQueryData.offer_info.promo_code}
                          </TableCell>
                          <TableCell align="left">
                            {modalQueryData.offer_info.description}
                          </TableCell>
                          <TableCell align="left">
                            {modalQueryData.offer_info.minimum_order_value}
                          </TableCell>
                          <TableCell align="left">
                            {
                              modalQueryData.offer_info.offer_info
                                .max_offer_value
                            }
                          </TableCell>
                          <TableCell align="left">
                            {
                              modalQueryData.offer_info.offer_info
                                .percent_discount
                            }
                          </TableCell>
                        </TableRow>
                      ) : null}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

export default EnquiryModal;
