import React, { useState } from "react";
import { useParams } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { Table } from "react-bootstrap";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TableContainer from "@material-ui/core/TableContainer";
import config from "../../../Utility/config";
import { BootstrapButton } from "../../../Utility/Style";
import {
  uploadProductImagesSkuLabels,
  uploadImagesButtonConstants,
  reOrderMessage,
} from "../../../Utility/stringConstant";
import * as FormData from "form-data";
import * as imageConversion from "image-conversion";
import axios from "axios";
import { TableBody } from "@material-ui/core";
import ModalView from "./ModalView";
import Box from "@material-ui/core/Box";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const UploadImagesRows = ({ individualProductData, businessId }) => {
  const [file, setFile] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState("");
  const { circleId, authToken } = useParams();
  const uploadedPhoto = [];
  const [previouslyUploadedProdutImages, setPreviouslyUploadedProductimages] =
    useState(individualProductData.product_images);
  const [listOfTotalImages, setListOfTotalImages] = useState(
    individualProductData.product_images
  );
  const [confirmButtonState, setConfirmButtonState] = useState(false);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleConfirmButtonStateDisabled = () => {
    setConfirmButtonState(true);
  };

  function uploadSingleFile(e) {
    const image = e.target.files[0];
    const imageType = e.target.files[0].type;
    if (imageType == "image/jpeg" || imageType == "image/png" || imageType == "image/jpg") {
      //compressing the image upto 200kb
      imageConversion.compressAccurately(image, 200).then((res) => {
        //The res in the promise is a compressed Blob type (which can be treated as a File type) file;
        setFile([
          ...file,
          {
            compressedImageUrl: URL.createObjectURL(res),
            compressedImageFile: res,
          },
        ]);
      });
    } else {
      setModalTitle("Wrong Image format");
      setModalContent("Please choose only jpeg, jpg or png images.");
      return handleModalOpen();
    }
  }

  async function uploadPhotoAction() {
    let data;
    handleConfirmButtonStateDisabled();
    if (tabValue == 0 && file.length > 0) {
      //upload image to s3 bucket and push the image link
      for (let i = 0; i < file.length; i++) {
        const photoFile = file[i].compressedImageFile;
        let formData = new FormData();
        formData.append("file", photoFile);
        await axios
          .post(config.postPhotoUrl, formData, {
            headers: {
              Authorization: "JWT " + { authToken }.authToken,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => uploadedPhoto.push(response.data));
      }
      const finalImagesToUpload = mergeImagesArrays();
      setListOfTotalImages(finalImagesToUpload);
      setPreviouslyUploadedProductimages(finalImagesToUpload);
      data = { images: finalImagesToUpload };
      handleUploadPhotoRequestAction(data);
    } else {
      data = { images: listOfTotalImages };
      handleUploadPhotoRequestAction(data);
    }
    setConfirmButtonState(false);
    return;
  }

  const handleUploadPhotoRequestAction = (data) => {
    axios
      .patch(
        config.getBusinessUrl +
          businessId +
          "/catalog/products/" +
          individualProductData.id,
        data,
        {
          headers: {
            Authorization: "JWT " + { authToken }.authToken,
          },
        }
      )
      .then((response) => {
        setModalTitle("Confirmation Message");
        setModalContent(
          `Images get updated for ${individualProductData.product_name}`
        );
        setConfirmButtonState(false);
        setFile([]);
        return handleModalOpen();
      });
  };

  function mergeImagesArrays() {
    const finalUploadedPhoto = uploadedPhoto;
    const finalPreviouslyUploadedProdutImagesCheck =
      previouslyUploadedProdutImages !== null
        ? previouslyUploadedProdutImages
        : [];
    return [...finalUploadedPhoto, ...finalPreviouslyUploadedProdutImagesCheck];
  }

  function deleteFile(e) {
    const newArrayOfRecentUploads = file.filter((item, index) => index !== e);
    setFile(newArrayOfRecentUploads);
  }
  function previouslyAddedDeleteFile(e) {
    const newArrayOfPreviousUploads = listOfTotalImages.filter(
      (item, index) => index !== e
    );
    setPreviouslyUploadedProductimages(newArrayOfPreviousUploads);
    setListOfTotalImages(newArrayOfPreviousUploads);
  }

  const [tabValue, setTabValue] = useState(0);

  const handleChangeTabValue = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <ModalView
        title={modalTitle}
        content={modalContent}
        open={modalOpen}
        handleClose={handleModalClose}
      />
      <TableCell style={{ borderLeft: "1px solid #E0E0E0" }} align="left">
        <strong>{individualProductData.id}</strong>
      </TableCell>
      <TableCell style={{ borderLeft: "1px solid #E0E0E0" }} align="left">
        {individualProductData.product_name}
      </TableCell>
      <TableCell style={{ borderLeft: "1px solid #E0E0E0" }} align="left">
        <TableContainer
          style={{
            height: "100%",
            display: "block",
          }}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {uploadProductImagesSkuLabels.map((column) => (
                  <TableCell
                    key={column.id}
                    align="left"
                    style={{ border: "1px solid #E0E0E0" }}
                  >
                    <strong>{column.label}</strong>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {individualProductData.skus.map((sku) => {
                return (
                  <TableRow key={sku.sku_id}>
                    <TableCell
                      style={{
                        border: "1px solid #E0E0E0",
                      }}
                    >
                      {sku.sku_id}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "1px solid #E0E0E0",
                      }}
                    >
                      {sku.sku_code}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </TableCell>
      <TableCell
        style={{
          borderLeft: "1px solid #E0E0E0",
        }}
      >
        <div>
          <Tabs
            value={tabValue}
            onChange={handleChangeTabValue}
            aria-label="simple tabs example"
          >
            <Tab label="Upload Images" {...a11yProps(0)} />
            <Tab label="Re-order Images" {...a11yProps(1)} />
          </Tabs>
        </div>
        <TabPanel value={tabValue} index={0}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {listOfTotalImages !== null
              ? listOfTotalImages.map((image, index) => {
                  return (
                    <div key={index}>
                      <div
                        style={{
                          border: "1px solid #E0E0E0",
                          padding: "8px",
                          marginBottom: "16px",
                          display: "flex",
                        }}
                      >
                        <Grid container>
                          <Grid item sm={10}>
                            <img
                              style={{
                                objectFit: "cover",
                              }}
                              height={100}
                              src={image.photo_url}
                            />
                          </Grid>
                          <Grid item sm={2}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <IconButton
                                aria-label="delete"
                                onClick={() => previouslyAddedDeleteFile(index)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>

          {file.length > 0 &&
            file.map((item, index) => {
              return (
                <div key={index}>
                  <div
                    style={{
                      border: "1px solid #E0E0E0",
                      display: "flex",
                      padding: "8px",
                      marginBottom: "16px",
                    }}
                    key={item.compressedImageUrl}
                  >
                    <Grid container>
                      <Grid item xs={10}>
                        <img
                          height={100}
                          src={item.compressedImageUrl}
                          alt=""
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <IconButton
                            aria-label="delete"
                            onClick={() => deleteFile(index)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              );
            })}
          <BootstrapButton variant="contained" component="label">
            {uploadImagesButtonConstants.chooseFile}
            <input type="file" onChange={uploadSingleFile} hidden />
          </BootstrapButton>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <DragDropContext
            onDragEnd={(param) => {
              const srcIndex = param.source.index;
              const desIndex = param.destination?.index;
              listOfTotalImages.splice(
                desIndex,
                0,
                listOfTotalImages.splice(srcIndex, 1)[0]
              );
              setListOfTotalImages(listOfTotalImages);
            }}
          >
            <div>
              <Droppable droppableId="productImagesList">
                {(provided, _) => (
                  <div
                    style={{ border: "1px solid #ffffff" }}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {listOfTotalImages != null ? (
                      listOfTotalImages?.map((item, index) => (
                        <Draggable
                          key={index}
                          draggableId={`draggable-${index}`}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                              style={{
                                ...provided.draggableProps.style,
                                border: "1px solid #E0E0E0",
                                display: "flex",
                                padding: "8px",
                                marginBottom: "16px",
                                backgroundColor: "#ffffff",
                                boxShadow: snapshot.isDragging
                                  ? "0 0 .4rem #666"
                                  : "none",
                              }}
                            >
                              <Grid container>
                                <Grid item xs={10}>
                                  <img
                                    style={{
                                      objectFit: "cover",
                                    }}
                                    height={100}
                                    src={item.photo_url}
                                  />
                                </Grid>
                              </Grid>
                            </div>
                          )}
                        </Draggable>
                      ))
                    ) : (
                      <Typography align="center">
                        <i>{reOrderMessage}</i>
                      </Typography>
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </DragDropContext>
        </TabPanel>
      </TableCell>
      <TableCell
        style={{
          borderLeft: "1px solid #E0E0E0",
        }}
      >
        <BootstrapButton
          onClick={uploadPhotoAction}
          variant="contained"
          component="label"
          disabled={confirmButtonState}
        >
          {uploadImagesButtonConstants.confirm}
        </BootstrapButton>
      </TableCell>
    </>
  );
};
