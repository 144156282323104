// tableHeaders id for eye button
const constOrderId = "id";
const constOrderCreatedData = "Order_created_date";
const constOrderLastUpdatedData = "last_update";
const constOrderTimeElapsed = "order_time_elapsed";
const constIsONDCOrder = "is_ondc_order";
const constOrderStatus = "Status";
const constOrderSeller = "Seller";
const constOrderAmount = "Order_amt";
const constOrderDeliveryType = "Delivery_Type";
const constOrderDeliveryCharges = "Delivery_Charges";
const constOrderOtherCharges = "Other_charges";
const constOrderItemTotal = "Item_Total";
const constOrderDeliveryAgent = "Delivery_Agent";
const constOrderPaymentStatus = "Payment_Status";
const constOrderPaymentMode = "Payment_mode";
const constOrderPaymentId = "Payment_id";
const constOrderCustomerName = "Customer_name";
const constOrderCustomerNumber = "Customer_number";
const constOrderCustomerAddress = "Customer_address";
const constOrderLongId = "long_id";
const constOrderType = "Order_Type";
const constTrue = "Yes";
const constFalse = "No";
const constNotAvailable = "Not Available";
const constDocument = "Document";
const constSuccess = "SUCCESS";
const constOrderTypePnD = "PICK_N_DROP";
const constCustomerPendingStatus = " Customer  Pending ";
const labelUrl = "Label URL";
const manifestUrl = "Manifest URL";
const invoiceUrl = "Invoice URL";
const orderInvoiceUrl = "Order Invoice URL";
const constHelpText = "This is the case of pay before order. The customer clicked on ‘Pay & Order’ but either did not make the payment or the payment failed. Thus, the order could not be placed.";
const paymentFields = {
  payment_type: "payment_type",
  amount: "amount",
  status: "status",
  refund_amount: "refund_amount",
  pretty_address: "pretty_address",
  via: "via",
};
const externalContactKeys = {
  name : "name",
  phone : "phone",
};
const thirdPartyDeliveryKeys = {
  delivery_type : "delivery_type",
  order_id : "order_id",
  shipment_id : "shipment_id",
  awb_code : "awb_code",
  label_url : "label_url",
  manifest_url : "manifest_url",
  invoice_url : "invoice_url",
};
const fieldsInTheOrderAPI = {
  pickup_address: "pickup_address",
  payment_info: "payment_info",
  da_info: "da_info",
};
// time conversion numbers
const timeForConversionInSeconds = 1000;
const timeForConversionInMinutes = 60;
const timeForConversionInHours = 3600;
const timeForConversionInDays = 86400;

// kilometer conversion numbers
const numberForConversionInKilometers = 1000;
//DA Constants
const daConstants = {
  profile_name: "profile_name",
};
// smartbox keys
const smartBoxKeys = {
  compartment_id: "smartbox_compartment_id",
  pretty_address: "pretty_address",
  smartbox_delivery: "smartbox_delivery",
  smartbox_id: "smartbox_id",
};
//order data modal headers and sub-headers
const orderDataModalHeadersSubheaders = {
  order_details: "ORDER DETAILS",
  contact_details: "CONTACT DETAILS",
  notes: "NOTES",
  summary: "SUMMARY",
  customer_note_images: "CUSTOMER NOTE IMAGES",
  smart_box: "SMART BOX",
  smart_box_manual_delivery: "SMARTBOX MANUAL DELIVERY",
  smart_box_id: "SMARTBOX ID",
  smart_box_pretty_address: "PRETTY ADDRESS",
  smart_box_compartment_id: "COMPARTMENT ID",
  order_ID: "Order ID",
  order_short_ID: "order short id (order id)",
  delivery_slot: "Delivery Slot",
  is_ondc_order: "ONDC Order",
  order_trail: "ORDER TRAIL",
  total_other_charges: "TOTAL OTHER CHARGES",
  customer_note: "Customer Note",
  cancellation_note: "Cancellation Note",
  DA_name: "DA Name",
  item_type: "Item Type",
  rating: "RATING",
  da_rating:"DA Rating",
  order_rating:"Order Rating",
  delivery_automation_status: "Delivery Automation Status",
  external_contact_info : "EXTERNAL CONTACT DETAILS",
  name : "Name",
  phone : "Phone",
  third_party_delivery_details : "THIRD PARTY DELIVERY DETAILS",
  bpp_name : "Buyer App Name",
  order_invoice_url : "Order Invoice Url",

};

const fcmTokenStatusAlertTokenRegistration = {
  new_token_registered:
    "You are successfully registered and will receive notifications from us.",
  token_already_registered:
    "You are already registered for getting notifications from us.",
};

const fcmTokenStatusCodeForTokenRegistration = {
  new_token_status_code: 201,
  already_present_token_status_code: 208,
};

const orderStatus = {
  merchant_cancelled: "MERCHANT_CANCELLED",
  customer_cancelled: "CUSTOMER_CANCELLED",
  provider_cancelled: "PROVIDER_CANCELLED",
  order_completed: "COMPLETED",
};

const orderDetailsLabels = [
  {
    id: 10,
    label: "SKU",
  },
  {
    id: 11,
    label: "Quantity",
  },
  {
    id: 12,
    label: "Unit Price",
  },
  {
    id: 13,
    label: "Product Name",
  },
  {
    id: 14,
    label: "Variation",
  },
  {
    id: 15,
    label: "Composition",
  },
];

const orderTrailedLabels = [
  {
    id: 16,
    label: "Event",
  },
  {
    id: 17,
    label: "Event Created Time",
  },
  {
    id: 18,
    label: "Event Info",
  },
];

const orderOtherChangesLabels = [
  {
    id: 19,
    label: "Name",
  },
  {
    id: 20,
    label: "Value",
  },
];

const contactDetailLables = [
  {
    id: 21,
    label: "Seller Name",
  },
  {
    id: 22,
    label: "Customer Name",
  },
  {
    id: 23,
    label: "Pickup Address",
  },
  {
    id: 24,
    label: "Delivery Address",
  },
  {
    id: 25,
    label: "Business Phone Number",
  },
  {
    id: 26,
    label: "Customer Phone Number",
  },
  {
    id: 27,
    label: "Delivery Distance",
  },
];

const summaryLabels = [
  {
    id: 28,
    label: "Order Short ID",
  },
  {
    id: 29,
    label: "Item Total",
  },
  {
    id: 30,
    label: "Order Total",
  },
  {
    id: 31,
    label: "Order Charges",
  },
  {
    id: 32,
    label: "Delivery Charges",
  },
  {
    id: 33,
    label: "Offer Discount",
  },
  {
    id: 34,
    label: "Product Discount",
  },
  {
    id: 35,
    label: "Payment Amount",
  },
  {
    id: 36,
    label: "Refund Amount",
  },
];

const uploadProductImagesLabels = [
  {
    id: "1",
    label: "Product ID",
  },
  {
    id: "2",
    label: "Product Name",
  },
  {
    id: "3",
    label: "SKUs",
  },
  {
    id: "4",
    label: "Product Image",
  },
  {
    id: "5",
    label: "",
  },
];

const uploadProductImagesSkuLabels = [
  {
    id: "1",
    label: "SKU ID",
  },
  {
    id: "2",
    label: "SKU Name",
  },
];

const thirdPartyDeliveryLabels = [
  {
    id : "1",
    label: "Delivery Type",
  },
  {
    id : "2",
    label: "Order ID",
  },
  {
    id : "3",
    label: "Shipment ID",
  },
  {
    id : "4",
    label: "AWB Code",
  },
  {
    id : "5",
    label: "Label",
  },
  {
    id : "6",
    label: "Manifest",
  },
  {
    id : "7",
    label: "Invoice",
  },
];

const ratingKeys= {
  da_rating : "da_rating",
  order_rating: "order_rating"
};

// const navbarHeader = "Order Details";
const navbarHeader = ["Order Details", "Orders", "Enquiries"];

const filterModifiedDate = "FILTER BY ORDER MODIFIED DATE";
const filterCreatedDate = "FILTER BY ORDER CREATED DATE";
const datePickerOr = "OR";

const enquiryModalHeader = {
  enquiry_order: "ENQUIRY ORDER DATA",
  items_details: "Cart Items Details",
  other_charges: "Other Charges",
  offer_details: "Offer Details",
};

const equiryTableStatus = {
  awaitingConfirm: "AWAITING_CONFIRM",
  awaitingInit: "AWAITING_INIT",
  inProgress: "In Progress",
};

const enquiryModal = {
  cartId: "Cart ID",
};

const uploadImagesButtonConstants = {
  chooseFile: "Choose File",
  confirm: "Confirm",
};

const uploadImagesCategoryFilterConstants = {
  categories: "CATEGORIES",
  selectCategory: "Select Category...",
  subCategories: "SUB-CATEGORIES",
  selectSubCategories: "Select Sub-Category...",
};

export const cancellationOptions = [
  {
    label: "Select a reason for cancellation",
    value: null,
    disabled: true,
    selected: true,
  },
  { label: "Customer not interested", value: "Customer not interested" },
  {
    label: "Seller unable to fulfill the order",
    value: "Seller unable to fulfill the order",
  },
  {
    label: "Payment related issues faced by customer",
    value: "Payment related issues faced by customer",
  },
  { label: "Change in delivery address", value: "Change in delivery address" },
  { label: "Unable to contact customer", value: "Unable to contact customer" },
  { label: "Unable to contact seller", value: "Unable to contact seller" },
  {
    label: "Other issues in order fulfillment",
    value: "Other issues in order fulfillment",
  },
  {
    label: "Customer wants to add more products",
    value: "Customer wants to add more products",
  },
];
export const orderStatusHelperText = "Please Select the Order Status";
export const cancellationNoteHelperText =
  "Cancellation note is mandatory for cancelling order";
export const codReceivedHelperText =
  "Selecting COD received by is mandatory for completing the order for COD order";

export const codReceivedByOptions = [
  {
    label: "COD Received by",
    value: null,
  },
  {
    label: "Agent",
    value: "AGENT",
  },
  {
    label: "Provider",
    value: "PROVIDER",
  },
  {
    label: "Merchant",
    value: "MERCHANT",
  },
];

export const orderStatusOptions = [
  {
    label: "Select the Order Status",
    value: null,
    disabled: true,
    selected: true,
  },
  { label: "Merchant Accept", value: "MERCHANT_ACCEPT" },
  { label: "Mark As Ready", value: "MARK_AS_READY" },
  { label: "Completed", value: "COMPLETED" },
  { label: "Cancel", value: "PROVIDER_CANCELLED" },
];

export const reOrderMessage = "Please add images to re-order";
export const platformCharges = "Platform Charges";

export {
  constOrderId,
  constOrderCreatedData,
  constOrderLastUpdatedData,
  constOrderTimeElapsed,
  constIsONDCOrder,
  constOrderStatus,
  constOrderSeller,
  constOrderAmount,
  constOrderDeliveryType,
  constOrderDeliveryCharges,
  constOrderOtherCharges,
  constOrderItemTotal,
  constOrderDeliveryAgent,
  constOrderPaymentStatus,
  constOrderPaymentMode,
  constOrderPaymentId,
  constOrderCustomerName,
  constOrderCustomerNumber,
  constOrderCustomerAddress,
  constOrderLongId,
  constTrue,
  constFalse,
  constNotAvailable,
  timeForConversionInSeconds,
  timeForConversionInMinutes,
  timeForConversionInHours,
  timeForConversionInDays,
  orderDetailsLabels,
  orderTrailedLabels,
  orderOtherChangesLabels,
  contactDetailLables,
  summaryLabels,
  numberForConversionInKilometers,
  orderDataModalHeadersSubheaders,
  fcmTokenStatusAlertTokenRegistration,
  fcmTokenStatusCodeForTokenRegistration,
  navbarHeader,
  orderStatus,
  filterModifiedDate,
  filterCreatedDate,
  datePickerOr,
  uploadProductImagesLabels,
  uploadProductImagesSkuLabels,
  enquiryModalHeader,
  equiryTableStatus,
  enquiryModal,
  uploadImagesButtonConstants,
  uploadImagesCategoryFilterConstants,
  constDocument,
  constSuccess,
  smartBoxKeys,
  daConstants,
  paymentFields,
  fieldsInTheOrderAPI,
  constOrderType,
  constOrderTypePnD,
  constCustomerPendingStatus,
  constHelpText,
  ratingKeys,
  externalContactKeys,
  thirdPartyDeliveryLabels,
  thirdPartyDeliveryKeys,
  labelUrl,
  manifestUrl,
  invoiceUrl,
  orderInvoiceUrl,
};
