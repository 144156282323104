const orderFilters = [
  {
    id: 11,
    label: "Created",
    value: "CREATED",
  },
  {
    id: 12,
    label: "Completed",
    value: "COMPLETED",
  },
  {
    id: 13,
    label: "Assigned to DA",
    value: "ASSIGNED_TO_DA",
  },
  {
    id: 14,
    label: "Merchant Updated",
    value: "MERCHANT_UPDATED",
  },
  {
    id: 15,
    label: "Customer Pending",
    value: "CUSTOMER_PENDING",
  },
  {
    id: 16,
    label: "Provider Cancelled ",
    value: "PROVIDER_CANCELLED",
  },
  {
    id: 17,
    label: "Ready for Pickup ",
    value: "READY_FOR_PICKUP",
  },
  {
    id: 18,
    label: "Merchant Accepted",
    value: "MERCHANT_ACCEPTED",
  },
  {
    id: 19,
    label: "Requesting to DA",
    value: "REQUESTING_TO_DA",
  },

  {
    id: 20,
    label: "Picked Up By DA",
    value: "PICKED_UP_BY_DA",
  },
  {
    id: 21,
    label: "Merchant Cancelled",
    value: "MERCHANT_CANCELLED",
  },
  {
    id: 22,
    label: "Customer Cancelled",
    value: "CUSTOMER_CANCELLED",
  },
  {
    id: 23,
    label: "DA Manager Accepted",
    value: "DA_MGR_ACCEPTED",
  },
  {
    id: 24,
    label: "DA Manager Cancelled",
    value: "DA_MGR_CANCELLED",
  },
];

const paymentFilters = [
  {
    id: 12,
    label: "Initiated",
    value: "INITIATED",
  },
  {
    id: 14,
    label: "Rejected",
    value: "REJECTED",
  },
  {
    id: 15,
    label: "Success",
    value: "SUCCESS",
  },
  {
    id: 16,
    label: "Fail ",
    value: "FAIL",
  },
  {
    id: 19,
    label: "Pending",
    value: "PENDING",
  },
];

const deliveryFilters = [
  {
    id: 12,
    label: "Self Pickup",
    value: "SELF_PICK_UP",
  },
  {
    id: 13,
    label: "DA Delivery",
    value: "DA_DELIVERY",
  },
  {
    id: 14,
    label: "Smartbox Delivery",
    value: "SMART_BOX_DELIVERY",
  },
];

const homepageTableColumns = [
  {
    id: "id",
    label: "OrderId",
    minWidth: 50,
    align: "left",
  },
  {
    id: "Order_created_date",
    label: "Order Created Date",
    minWidth: 200,
    align: "left",
  },
  {
    id: "last_update",
    label: "Order Last Modified Date",
    minWidth: 200,
    align: "left",
  },
  // {
  //   id: "order_time_elapsed",
  //   label: "Order Time Elapsed",
  //   minWidth: 100,
  //   align: "left",
  // },
  // {
  //   id: "is_ondc_order",
  //   label: "ONDC Order",
  //   minWidth: 100,
  //   align: "left",
  // },
  {
    id: "Order Status",
    label: "Status",
    minWidth: 130,
    align: "left",
  },
  {
    id: "Serviceability",
    label: "Serviceability",
    minWidth: 200,
    align:"left"
  },
  {
    id: "Download",
    label: "Download",
    minWidth: 200,
    align:"left"
  },
  {
    id: "delivery_status",
    label: "Delivery Status",
    minWidth: 135,
    align: "left",
  },
  // {
  //   id: "Seller",
  //   label: "Seller",
  //   minWidth: 120,
  //   align: "left",
  // },
  {
    id: "Order_amt",
    label: "Order Amt",
    minWidth: 80,
    align: "left",
  },
  // {
  //   id: "Delivery_Type",
  //   label: "Delivery Type",
  //   minWidth: 100,
  //   align: "left",
  // },
  // {
  //   id: "Delivery_Charges",
  //   label: "Delivery Charges",
  //   minWidth: 50,
  //   align: "left",
  // },
  // {
  //   id: "Other_charges",
  //   label: "Other Charges",
  //   minWidth: 50,
  //   align: "left",
  // },
  // {
  //   id: "Item_Total",
  //   label: "Item Total",
  //   minWidth: 50,
  //   align: "left",
  // },
  // {
  //   id: "Delivery_Agent",
  //   label: "Delivery Agent",
  //   minWidth: 50,
  //   align: "left",
  // },
  // {
  //   id: "Payment_Status",
  //   label: "Payment Status",
  //   minWidth: 50,
  //   align: "left",
  // },
  // {
  //   id: "Payment_mode",
  //   label: "Payment Mode",
  //   minWidth: 50,
  //   align: "left",
  // },
  {
    id: "Customer_name",
    label: "Customer Name",
    minWidth: 50,
    align: "left",
  },
  {
    id: "Customer_number",
    label: "Customer Number",
    minWidth: 50,
    align: "left",
  },
  {
    id: "Customer_address",
    label: "Customer Address",
    minWidth: 200,
    align: "left",
  },
  // {
  //   id: "Order_Type",
  //   label: "Order Type",
  //   minWidth: 120,
  //   align: "left",
  // },
  // {
  //   id: "long_id",
  //   label: "Long OrderId",
  //   minWidth: 300,
  //   align: "left",
  // },
  
  
];

const queriesTableColumns = [
  {
    id: "cart_id",
    label: "Cart ID",
    minWidth: 100,
    align: "left",
  },
  {
    id: "ondc_enquiry_timeout",
    label: "Enquiry Timeout",
    minWidth: 200,
    align: "left",
  },
  {
    id: "ondc_enquiry_status",
    label: "Status",
    minWidth: 150,
    align: "left",
  },
  {
    id: "created",
    label: "Created",
    minWidth: 200,
    align: "left",
  },
  {
    id: "modified",
    label: "Modified",
    minWidth: 200,
    align: "left",
  },
  {
    id: "item_total",
    label: "Item Total",
    minWidth: 50,
    align: "left",
  },
  {
    id: "other_charges",
    label: "Other Charges",
    minWidth: 50,
    align: "left",
  },
  {
    id: "delivery_charges",
    label: "Delivery charges",
    minWidth: 50,
    align: "left",
  },
  {
    id: "offer_discount",
    label: "Offer Discount",
    minWidth: 50,
    align: "left",
  },
  {
    id: "order_total",
    label: "Order Total",
    minWidth: 50,
    align: "left",
  },
  {
    id: "business_name",
    label: "Business Name",
    minWidth: 100,
    align: "left",
  },
  {
    id: "pickup_address",
    label: "Pickup Address",
    minWidth: 300,
    align: "left",
  },
  {
    id: "delivery_address",
    label: "Delivery Address",
    minWidth: 300,
    align: "left",
  },
  {
    id: "delivery_distance",
    label: "Delivery Distance",
    minWidth: 100,
    align: "left",
  },
];

const enquiryOtherChargesHeader = ["Charge Name", "Charge Value"];

const enquiryOfferHeader = [
  {
    id: 1,
    label: "Promo Code",
  },
  {
    id: 2,
    label: "Description",
  },
  {
    id: 3,
    label: "Minimum Order Value",
  },
  {
    id: 4,
    label: "Maximum Offer Value",
  },
  {
    id: 5,
    label: "Percent Discount",
  },
];

const enquiryCartHeader = [
  {
    id: 1,
    label: "Product Name",
  },
  {
    id: 2,
    label: "Quantity Value",
  },
  {
    id: 3,
    label: "Quantity Unit",
  },
  {
    id: 4,
    label: "Unit Price",
  },
  {
    id: 5,
    label: "Unit Price With Addons",
  },
  {
    id: 6,
    label: "Addon Details",
  },
];

export {
  orderFilters,
  paymentFilters,
  deliveryFilters,
  homepageTableColumns,
  queriesTableColumns,
  enquiryOtherChargesHeader,
  enquiryOfferHeader,
  enquiryCartHeader,
};
