import React from "react";
import { UploadImagesRows } from "./UploadImagesRows";
import TableRow from "@material-ui/core/TableRow";

export const UploadImagesData = ({ rows, businessId }) => {
  return rows.map((individualProductData) => (
    <TableRow key={individualProductData.id}>
      <UploadImagesRows
        individualProductData={individualProductData}
        businessId={businessId}
      />
    </TableRow>
  ));
};
