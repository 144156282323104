import React from "react";

import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import { blockStyle } from "../../../Utility/Style";

const useStyles = makeStyles(blockStyle);

const Counter = (props) => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.flexDiv1}>
        <Card className={classes.card}>
          <Typography className={classes.orderCount}>
            Orders {props.orderCount}
          </Typography>
        </Card>
      </div>
    </div>
  );
};
export default Counter;
