import React, { useState } from "react";
import firebase from "firebase/app";
import "firebase/messaging";
import { Content, Notification } from "./Components";
import { getClientToken, onFcmOrderMessageListener } from "./firebase";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import NotificationSnackbar from "./Components/Notification/NotificationSnackbar";
import "./App.css";
import UploadImages from "./Components/UploadImages/UploadImages";
import Queries from "./Components/Queries/Queries";

function App() {
  const [showNotificationSnackbar, setShowNotificationSnackbar] =
    useState(false);
  const [notification, setNotification] = useState({ body: "" });
  const [isNewNotificationPopped, setNewNotificationPopped] = useState(true);

  if (firebase.messaging.isSupported()) {
    getClientToken();
  }
  onFcmOrderMessageListener()
    .then((payload) => {
      setShowNotificationSnackbar(true);
      setNewNotificationPopped(false);
      setNotification({
        body: payload.notification.body,
      });
      console.log(payload);
    })
    .catch((err) => console.log("failed: ", err));

  const handleCloseNotificationSnackbar = () => {
    setShowNotificationSnackbar(false);
  };

  const handleNewNotificationPopped = (state) => {
    setNewNotificationPopped(state);
    localStorage.setItem("backgroundNewNotification", false);
  };
  return (
    <div className="App">
      <NotificationSnackbar
        notificationBody={notification.body}
        showNotificationSnackbar={showNotificationSnackbar}
        onHandleCloseNotificationSnackbar={handleCloseNotificationSnackbar}
      />
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path="/:circleId/:authToken/"
            render={(props) => (
              <Content
                {...props}
                isNewNotificationPopped={isNewNotificationPopped}
                onSetNewNotificationPopped={handleNewNotificationPopped}
              />
            )}
          />
          <Route
            exact
            path="/:circleId/:authToken/notification/"
            render={(props) => (
              <Notification
                {...props}
                isNewNotificationPopped={isNewNotificationPopped}
                onSetNewNotificationPopped={handleNewNotificationPopped}
              />
            )}
          />
          <Route
            exact
            path="/:circleId/:businessId/:authToken/uploadImages/"
            render={(props) => (
              <UploadImages
                {...props}
                isNewNotificationPopped={isNewNotificationPopped}
                onSetNewNotificationPopped={handleNewNotificationPopped}
              />
            )}
          />
          {/* <Route
            exact
            path="/:circleId/:authToken/queries/"
            render={(props) => (
              <Queries
                {...props}
                isNewNotificationPopped={isNewNotificationPopped}
                onSetNewNotificationPopped={handleNewNotificationPopped}
              />
            )}
          />
          /> */}
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
