// const IS_STAGING = process.env.REACT_APP_IS_STAGING; // false if is_staging not present
// const IS_LOCAL = process.env.REACT_APP_IS_LOCAL;
// const IS_PROD = process.env.REACT_APP_IS_PROD;

// const eSamudaayBaseUrl = IS_PROD;
const eSamudaayBaseUrl = process.env.REACT_APP_ESAMUDAAY_URL;

const getOrderListUrl = eSamudaayBaseUrl + "api/v1/orders/";
const sendNotificationUrl = eSamudaayBaseUrl + "api/v1/clusters/";
const getOndcEnquiryCart = eSamudaayBaseUrl + "api/v1/orders/cart";
const getClusterListUrl = eSamudaayBaseUrl + "api/v1/clusters/";
const getBusinessUrl = eSamudaayBaseUrl + "api/v1/businesses/";
const postPhotoUrl = eSamudaayBaseUrl + "api/v1/media/photo/";
const getNotificationListUrl =
  eSamudaayBaseUrl + "api/v1/notifications/persistent/";
const downloadOrderListUrl = eSamudaayBaseUrl + "api/v2/orders/download?";
const postNotificationUrl =
  eSamudaayBaseUrl + "api/v1/notifications/mobile/tokens";
const firebaseConfig = {
  apiKey: "AIzaSyCmRvGFndfULFczrz5BGXbUDTQbKZMc-Z8",
  authDomain: "esamudaay-customer.firebaseapp.com",
  databaseURL: "https://esamudaay-customer.firebaseio.com",
  projectId: "esamudaay-customer",
  storageBucket: "esamudaay-customer.appspot.com",
  messagingSenderId: "477395312345",
  appId: "1:477395312345:web:68417a4b1c698b9466e8c8",
  measurementId: "G-1JF5E0EZ5S",
};
const firebaseVapidKey =
  "BG47QRgfJkMhXYVRFr-UsnTpHgNwoiAYP5imRbafwdZo4YQvyR4Cwi6l6EDtS3rXVCOJ9Hiuk0HtyiNDuKQSvFM";
const config = {
  getOrderListUrl,
  getClusterListUrl,
  downloadOrderListUrl,
  postNotificationUrl,
  firebaseConfig,
  firebaseVapidKey,
  getNotificationListUrl,
  getBusinessUrl,
  postPhotoUrl,
  getOndcEnquiryCart,
  sendNotificationUrl,
};

export default config;
