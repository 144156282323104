import React, { useState, useEffect, useRef } from "react";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import Grid from "@material-ui/core/Grid";
import Select from "react-select";
import DatePicker from "./DatePicker";
import Filters from "./Filters";
import InputBox from "./InputBox";
import config from "../../../Utility/config";
import {
  orderFilters,
  paymentFilters,
  deliveryFilters,
} from "../../../Utility/filterList";
import { BootstrapButton } from "../../../Utility/Style";
import axios from "axios";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import PhoneNumberInputField  from './PhoneNumberInputField';



function FiltersCollapse({
  onHandleDateFromChange,
  onHandleDateToChange,
  onHandleDateFromChangeCreated,
  onHandleDateToChangeCreated,
  onHandleOrderFilterChange,
  onHandleDeliveryFilterChange,
  onHandlePaymentFilterChange,
  onHandleDaNameFilterChange,
  onHandleValueChangeBusinessName,
  onHandleValueChangePhoneNumber,
  onHandleValueChangeOrder,
  toDate,
  fromDate,
  toDateCreated,
  fromDateCreated,
  clickedRefFrom,
  clickedRefTo,
  clickedRefFromCreated,
  clickedRefToCreated,
  isCollapsed,
  onHandleRefreshFilters,
  textValueBusinessName,
  textValuePhoneNumber,
  textValueOrder,
  circleId,
  authToken,
}) {
  const [deliveryAgents, setDeliveryAgents] = useState([]);
  const orderStatusRef = useRef(null);
  const deliveryStatusRef = useRef(null);
  const paymentStatusRef = useRef(null);
  const daNameRef = useRef(null);
  const businessNameRef = useRef(null);
  const phoneNumberRef = useRef(null);

  const handleSelectDeliveryAgent = (e) => {
    onHandleDaNameFilterChange(e);
  };

  const scrollInputToTop = (inputRef) => {
    let distanceToScroll =
      window.pageYOffset + inputRef.current.getBoundingClientRect().top;
    window.scrollTo({ top: distanceToScroll, behavior: "smooth" });
  };

  useEffect(() => {
    axios
      .get(config.getClusterListUrl + circleId + "/deliveryagents", {
        headers: {
          Authorization: "JWT " + authToken,
        },
      })
      .then((response) => {
        const agents = [];
        response.data.results.forEach((deliveryAgents) => {
          agents.push({
            value: deliveryAgents.delivery_agent_id,
            label: deliveryAgents.name,
          });
        });
        setDeliveryAgents(agents);
      });
  }, []);

  return (
    <div>
      <Collapse orientation="horizontal" in={isCollapsed}>
        <Box>
          <Box alignItems="center" justifyContent="center" direction="row">
            <Box>
              <DatePicker
                onHandleDateFromChange={onHandleDateFromChange}
                onHandleDateToChange={onHandleDateToChange}
                onHandleDateFromChangeCreated={onHandleDateFromChangeCreated}
                onHandleDateToChangeCreated={onHandleDateToChangeCreated}
                toDate={toDate}
                fromDate={fromDate}
                toDateCreated={toDateCreated}
                fromDateCreated={fromDateCreated}
                clickedRefFrom={clickedRefFrom}
                clickedRefTo={clickedRefTo}
                clickedRefFromCreated={clickedRefFromCreated}
                clickedRefToCreated={clickedRefToCreated}
              ></DatePicker>
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          style={{ marginBottom: "30px" }}
        >
          <Grid item xs={6} md={2}>
            <Box
              style={{ margin: "0 5px" }}
              ref={orderStatusRef}
              onClick={() => scrollInputToTop(orderStatusRef)}
            >
              <h5>ORDER STATUS</h5>
              <Filters
                handleFilterChange={onHandleOrderFilterChange}
                filterUtility={orderFilters}
              ></Filters>
            </Box>
          </Grid>
          <Grid item xs={6} md={2}>
            <Box
              style={{ margin: "0 5px" }}
              ref={deliveryStatusRef}
              onClick={() => scrollInputToTop(deliveryStatusRef)}
            >
              <h5>DELIVERY TYPE</h5>
              <Filters
                handleFilterChange={onHandleDeliveryFilterChange}
                filterUtility={deliveryFilters}
              ></Filters>
            </Box>
          </Grid>
          <Grid item xs={6} md={2}>
            <Box
              style={{ margin: "0 5px" }}
              ref={paymentStatusRef}
              onClick={() => scrollInputToTop(paymentStatusRef)}
            >
              <h5>PAYMENT STATUS</h5>
              <Filters
                handleFilterChange={onHandlePaymentFilterChange}
                filterUtility={paymentFilters}
              ></Filters>
            </Box>
          </Grid>
          <Grid item xs={6} md={2}>
            <Box
              style={{ margin: "0 5px" }}
              ref={daNameRef}
              onClick={() => scrollInputToTop(daNameRef)}
            >
              <h5>DA NAME</h5>
              <Select
                isClearable={true}
                onChange={handleSelectDeliveryAgent}
                options={deliveryAgents}
              />
            </Box>
          </Grid>
          <Grid item xs={6} md={2}>
            <Box
              style={{ margin: "0 5px" }}
              ref={businessNameRef}
              onClick={() => scrollInputToTop(businessNameRef)}
            >
              <h5>BUSINESS NAME</h5>
              <InputBox
                onValueChange={onHandleValueChangeBusinessName}
                textValue={textValueBusinessName}
                id="business-name-search"
              />
            </Box>
          </Grid>
          
          
          <Grid item xs={6} md={2}>
            <Box style={{ margin: "0 5px" }}>
            <h5>PHONE NUMBER</h5>
              <PhoneNumberInputField
                id="phone-number-search"
                textValue={textValuePhoneNumber}
                onValueChange={onHandleValueChangePhoneNumber}
              />
            </Box>
          </Grid>
          
        </Box>
        
      </Collapse>
      
    </div>
  );
}

export default FiltersCollapse;
