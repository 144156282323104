import {
  timeForConversionInSeconds,
  timeForConversionInMinutes,
  timeForConversionInHours,
  timeForConversionInDays,
  orderStatus,
  equiryTableStatus,
  constNotAvailable,
} from "./stringConstant";

const getDateFormat = (date) => {
  if (date === null) return "Not Available";
  let d = new Date(date);
  return d.toDateString() + " | " + formatAMPM(d);
};

const epochDateConversionToUtc = (date) => {
  var d = new Date(date * 1000).toUTCString();
  let convertdDate = new Date(d);
  return convertdDate.toDateString();
};
const epochDateConversionToUtcDateAndTime = (date) => {
  if (date === null) return "Not Available";
  var d = new Date(date * 1000).toUTCString();
  let convertdDate = new Date(d);
  return convertdDate.toDateString() + " | " + formatAMPM(convertdDate);
};

/**
 * @param {number} value - This is containing paisa
 * @returns {number} - converted amount from paisa to rupee
 */
const convertPaisaToRupee = (value) => {
  if (value == null) {
    return value;
  }
  value = value / 100;
  return value;
};

function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}
const getCustomerAddress = (data) => {
  try {
    return `${data.geo_addr.house === null ? "" : data.geo_addr.house + ","} ${
      data.geo_addr.landmark === null ? "" : data.geo_addr.landmark + ","
    } ${data.pretty_address}`;
  } catch {
    return "Not Available";
  }
};
const getDeliveryAgentName = (data) => {
  try {
    return data.profile_name + "-" + data.user_profile.phone;
  } catch {
    return null;
  }
};

const getDateEpoch = (date) => {
  return Date.parse(date);
};
const differenceBetweenOrderCreatedOrderUpdated = (
  createdDate,
  updatedDate,
  orderStatusDetail
) => {
  let diffOrderUpdatedDateOrderCreatedDate;
  if (
    orderStatusDetail === orderStatus.merchant_cancelled ||
    orderStatusDetail === orderStatus.customer_cancelled ||
    orderStatusDetail === orderStatus.provider_cancelled ||
    orderStatusDetail === orderStatus.order_completed
  ) {
    diffOrderUpdatedDateOrderCreatedDate =
      (new Date(updatedDate) - new Date(createdDate)) /
      timeForConversionInSeconds;
  } else {
    diffOrderUpdatedDateOrderCreatedDate =
      (new Date() - new Date(createdDate)) / timeForConversionInSeconds;
  }
  if (diffOrderUpdatedDateOrderCreatedDate < timeForConversionInMinutes) {
    return convertInSeconds(diffOrderUpdatedDateOrderCreatedDate, "");
  } else if (
    diffOrderUpdatedDateOrderCreatedDate >= timeForConversionInMinutes &&
    diffOrderUpdatedDateOrderCreatedDate < timeForConversionInHours
  ) {
    return convertInMinutes(diffOrderUpdatedDateOrderCreatedDate, "");
  } else if (
    diffOrderUpdatedDateOrderCreatedDate >= timeForConversionInHours &&
    diffOrderUpdatedDateOrderCreatedDate < timeForConversionInDays
  ) {
    return convertInHours(diffOrderUpdatedDateOrderCreatedDate, "");
  } else if (diffOrderUpdatedDateOrderCreatedDate >= timeForConversionInDays) {
    return convertInDays(diffOrderUpdatedDateOrderCreatedDate);
  }
};
const convertInSeconds = (time, convertedTime) => {
  let timeConvertedInSeconds = `${Math.ceil(time)} sec`;
  return `${convertedTime} ${timeConvertedInSeconds}`;
};
const convertInMinutes = (time, convertedTime) => {
  let timeConvertedInMinutes = Math.floor(time / timeForConversionInMinutes);
  if (time - timeConvertedInMinutes * timeForConversionInMinutes > 0) {
    return convertInSeconds(
      time - timeConvertedInMinutes * timeForConversionInMinutes,
      convertedTime + `${timeConvertedInMinutes} min`
    );
  } else {
    return `${convertedTime} ${timeConvertedInMinutes}min`;
  }
};
const convertInHours = (time, convertedTime) => {
  let timeConvertedInHours = Math.floor(time / timeForConversionInHours);
  if (time - timeConvertedInHours * timeForConversionInHours > 0) {
    return convertInMinutes(
      time - timeConvertedInHours * timeForConversionInHours,
      convertedTime + `${timeConvertedInHours} hrs `
    );
  } else {
    return `${convertedTime} ${timeConvertedInHours}hrs `;
  }
};
const convertInDays = (time) => {
  let timeConvertedInDays = Math.floor(time / timeForConversionInDays);
  if (time - timeConvertedInDays * timeForConversionInDays > 0) {
    return convertInHours(
      time - timeConvertedInDays * timeForConversionInDays,
      `${timeConvertedInDays} days `
    );
  } else {
    return `${timeConvertedInDays}days `;
  }
};
const removeUnderscoreConvertInSmallcase = (data) => {
  if(data === undefined){
    return constNotAvailable;
  }
  const splittedData = data.split("_");
  let finalWord = "";
  for (let [word] in splittedData) {
    if (splittedData[word] === "DA") {
      finalWord += splittedData[word];
      continue;
    } else if (
      data === equiryTableStatus.awaitingConfirm ||
      data === equiryTableStatus.awaitingInit
    ) {
      return equiryTableStatus.inProgress;
    }
    finalWord +=
      " " +
      splittedData[word].charAt(0) +
      splittedData[word].substring(1).toLowerCase() +
      " ";
  }
  return finalWord;
};

const decreaseCartIdLength = (id) => {
  return id.substring(0, 8) + "...";
};

const convertTimestamptoTime = (unixTime) => {
  let dateObj = new Date(unixTime * 1000);
  let time = formatAMPM(dateObj);
  return time;
};
const convert_is_ondc_order = (is_ondc_order) => {
  if (is_ondc_order) {
    return "Yes";
  }
  return "No";
};
export {
  getCustomerAddress,
  getDateFormat,
  getDeliveryAgentName,
  getDateEpoch,
  differenceBetweenOrderCreatedOrderUpdated,
  removeUnderscoreConvertInSmallcase,
  convertPaisaToRupee,
  convertTimestamptoTime,
  epochDateConversionToUtc,
  epochDateConversionToUtcDateAndTime,
  decreaseCartIdLength,
  convert_is_ondc_order,
};
