import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

const blockStyle = {
  root: {
    padding: "10px",
  },
  card: {
    borderRadius: "25px",
  },
  orderCount: {
    padding: "10px 12px",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  inputTextField: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #cccccc",
    borderRadius: "5px",
    padding: "7px 4px",
  },
  inputSearch: {
    cursor: "pointer",
  },
  outerBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  innerBox: {
    margin: "20px 20px",
  },
  searchInputField: {
    width: "100%",
  },
  modalOuter: {
    width: "90%",
    display: "flex",
    margin: "0 auto",
  },
  modalPaper: {
    width: "100%",
    backgroundColor: "#fff",
    padding: "20px",
  },
  modal: {
    overflow: "scroll",
    height: "100%",
    display: "inline-block",
  },
  PhoneInputInput: {
    padding: "5px",
    borderRadius: "5px",
    border: "1px solid rgb(204,204,204)",
  },
};
const BootstrapButton = withStyles({
  root: {
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    padding: "6px 12px",
    border: "1px solid",
    lineHeight: 1.5,
    backgroundColor: "#DB8F48",
    borderColor: "#d0833b",
    // textTransform: "uppercase",
    color: "#fff",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      backgroundColor: "#d0833b",
      borderColor: "#d0833b",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#d0833b",
      borderColor: "#d0833b",
    },
  },
})(Button);
export { blockStyle, BootstrapButton };
