import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  FormControl,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { TextField, Typography } from "@material-ui/core";
import { getDateFormat } from "../../../Utility/paramsConvert";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import axios from "axios";

import { orderDetails, shippingDetails } from "../../../Utility/ModalHeaders";

function ViewServiceability({
  orderDataDisplayModalOpen,
  setOrderDataDisplayModalOpen,
  modalOrderData,
  orderDetail,
  authToken,
}) {
  const [firstName, setFirstName] = useState(false);
  const [pickup, setPickup] = useState([]);
  const [selectPickup, setSelectPickup] = useState([]);

  const fetchPickupAddress = () => {
    try {
      axios
        .get(`https://api.nest.esamudaay.com/api/v1/delhivery/pickup`, {
          headers: {
            Authorization: "JWT " + { authToken }.authToken,
          },
        })
        .then((response) => {
          setPickup(response.data.data);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPickupAddress();
  }, []);

  const createShipment = () => {
    try {
      axios
        .post(
          `https://api.nest.esamudaay.com/api/v1/delhivery/order/place/`,
          {
            shipments: [modalOrderData],
            pickup_location: {
              name: selectPickup.name,
              add: selectPickup.address,
              city: selectPickup.city,
              pin_code: selectPickup.pin_code,
              country: selectPickup.country,
              phone: selectPickup.phone
            },
          },
          {
            headers: {
              Authorization: "JWT " + { authToken }.authToken,
            },
          }
        )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          alert("Selected Client WareHouse does not exist", error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Modal open={orderDataDisplayModalOpen} style={{ width: "100%" }}>
      <Paper
        style={{
          width: "70%",
          margin: "auto",
          height: "84%",
          overflowY: "auto",
          marginTop: "50px",
        }}
      >
        <Box display="flex" justifyContent="flex-end">
          <IconButton
            onClick={() =>
              setOrderDataDisplayModalOpen(!orderDataDisplayModalOpen)
            }
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box>
          <Typography
            variant="h6"
            style={{ textTransform: "uppercase", paddingLeft: 20 }}
          >
            Order Details
          </Typography>
          <Box
            variant="middle"
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 10,
              paddingBottom: 10,
            }}
          >
            <Divider />
          </Box>
        </Box>
        <Grid
          container
          style={{
            paddingLeft: 20,
          }}
        >
          {orderDetails.map((item) => {
            return (
              <Grid
                item
                xs={6}
                style={{ display: "flex", gap: 5, paddingTop: 15 }}
              >
                <Typography variant="body1" style={{ fontWeight: "600" }}>
                  {item.label}:
                </Typography>
                {Object.entries(orderDetail).map(
                  (value) =>
                    item.id === value[0] &&
                    value[1] !== "" && (
                      <Typography key={value[0]}> {value[1]}</Typography>
                    )
                )}
              </Grid>
            );
          })}
        </Grid>
        <Box>
          <Typography
            variant="h6"
            style={{
              textTransform: "uppercase",
              paddingLeft: 20,
              paddingTop: 35,
            }}
          >
            Shipping Details
          </Typography>
          <Box
            variant="middle"
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 10,
              paddingBottom: 10,
            }}
          >
            <Divider />
          </Box>
        </Box>
        <Grid
          container
          style={{
            paddingLeft: 20,
          }}
        >
          {shippingDetails.map((item) => {
            return (
              <Grid
                xs={6}
                item
                style={{
                  display: "flex",
                  gap: 5,
                  width: "100%",
                  paddingTop: 15,
                }}
              >
                <Typography variant="body1" style={{ fontWeight: "600" }}>
                  {item.label}:
                </Typography>
                {Object.entries(modalOrderData).map(
                  (value) =>
                    item.id === value[0] &&
                    value[1] !== "" && (
                      <Typography key={value[0]}>{value[1]}</Typography>
                    )
                )}
              </Grid>
            );
          })}
        </Grid>
        <Box>
          <Typography
            variant="h6"
            style={{
              textTransform: "uppercase",
              paddingLeft: 20,
              paddingTop: 35,
            }}
          >
            Pickup Details
          </Typography>
          <Box
            variant="middle"
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 10,
              paddingBottom: 10,
            }}
          >
            <Divider />
          </Box>
        </Box>
        <Box
          style={{
            display: "flex",
            gap: 10,

            alignItems: "center",
            paddingLeft: "20px",
            paddingTop: "20px",
          }}
        >
          <Typography variant="body1" style={{ fontWeight: "600" }}>
            Select the WareHouse:
          </Typography>
          <FormControl size="small" variant="standard">
            <Select
              variant="outlined"
              placeholder="Select the warehouse"
              style={{ width: "300px" }}
              onChange={(e) => setSelectPickup(e.target.value)}
            >
              {Object.entries(pickup).map((item) => {
                return (
                  <MenuItem key={item[0]} value={item[1]}>
                    {item[1].name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "30px",
          }}
        >
          <Button
            onClick={createShipment}
            style={{
              backgroundColor: "#DB8F48",
              color: "white",
              textTransform: "capitalize",
            }}
          >
            Create Shipment
          </Button>
        </Box>
      </Paper>
    </Modal>
  );
}

export default ViewServiceability;
