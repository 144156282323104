/* eslint-disable no-use-before-define */
import React from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

export default function PhoneNumberInputField({
  onValueChange,
  textValue,
  placeholder = "Search...",
}) {
  return (
    <div>
      <PhoneInput
        onChange={onValueChange}
        value={textValue}
        debouncetimeout={200}
        placeholder={placeholder}
        defaultCountry="IN"
        smartCaret={true}
        withCountryCallingCode={true}
      />
    </div>
  );
}
